/**
 * Component PdfLine
 * @author: Luis Avello
 * @version 1.2.0
 * @updated 2023-07-14
 */
import jsPDF, { AcroFormCheckBox, AcroFormTextField } from "jspdf";
import logo from "../imgs/logo_varela.png";
import "jspdf-autotable";

export default function PdfLine(data) {
  const pdf = new jsPDF({ unit: "px", pageSize: "letter" });
  var width = pdf.internal.pageSize.getWidth();
  console.log("Ejecutando PdfLine");
  pdf.setFont("helvetica", "bold");
  pdf.setFontSize(11);

  //var height = pdf.internal.pageSize.getHeight()
  //pdf.setFontSize(14);
  pdf.setLineHeightFactor(2);

  pdf.text(["INSPECCIÓN DE LÍNEA"], width / 2, 20, { align: "center" });
  pdf.text("Varela Hermanos, S.A.", width / 2, 30, { align: "center" });
  pdf.addImage(logo, "JPEG", 20, 10, 30, 25);
  pdf.line(20, 35, width - 20, 35);
  pdf.setFontSize(8);
  pdf.text("ASC-F-054", width - 30, 10);
  pdf.text("Rev. 7", width - 30, 18);
  pdf.text("07-jul-21", width - 30, 26);
  //informacion de operaciones
  pdf.setFont("helvetica", "normal");
  pdf.text("INFORMACIÓN DE OPERACIONES", width / 4, 50, { align: "center" });
  pdf.rect(20, 40, width / 2 - 40, 12);
  pdf.text(
    [
      "Producto:",
      "Fecha de producción:",
      "Presentación:",
      "# de Línea:",
      "Hora de arranque:",
    ],
    20,
    60
  );

  pdf.text(
    [
      data.body.OPERATIONS_INFO.PRODUCT_NAME.VALUES[0],
      data.body.OPERATIONS_INFO.DATE_PRODUCTION.VALUES[0],
      data.body.OPERATIONS_INFO.PRODUCT_PRESENTATION.VALUES[0],
      data.body.OPERATIONS_INFO.LINE.VALUES[0],
      data.body.OPERATIONS_INFO.START_TIME.VALUES[0],
    ],
    100,
    60
  );

  //verificacion del sistema de trasiego
  pdf.text("VERIFICACIÓN DEL SISTEMA DE TRASIEGO", (width * 3) / 4, 50, {
    align: "center",
  });
  pdf.rect(width / 2 + 20, 40, width / 2 - 40, 12);
  pdf.text(
    ["Línea de trasiego:", "Mangueras:", "Bombas:", "Filtros:"],
    width / 2 + 20,
    60,
    { baseline: "top" }
  );
  //inspeccion de maquinas para el arranque
  pdf.text("INSPECCIÓN DE MÁQUINAS PARA EL ARRANQUE", width / 4, 130, {
    align: "center",
  });
  pdf.rect(20, 120, width / 2 - 40, 12);
  pdf.text(
    [
      "Nivel de llenado:",
      "Tapado:",
      "Etiquetado:",
      "Codificado de envase:",
      "Codificado cajas:",
    ],
    20,
    140
  );
  //analisis fisico-quimico
  pdf.text("ANALISIS FISICO-QUIMICO PARA EL ARRANQUE", (width * 3) / 4, 130, {
    align: "center",
  });
  pdf.rect(width / 2 + 20, 120, width / 2 - 40, 12);
  pdf.text(
    [
      "Grado Alcohólico Aparente:",
      "Dentro del rango",
      "pH",
      "Prueba Organoléptica:",
    ],
    width / 2 + 20,
    140
  );

  //verificacion del sistema de trasiego
  var lineaTrasiego = new AcroFormCheckBox();
  lineaTrasiego.fieldName = "Linea de trasiego";
  lineaTrasiego.Rect = [(width * 3) / 4, 55, 8, 8];
  pdf.addField(lineaTrasiego);
  var mangueras = new AcroFormCheckBox();
  mangueras.fieldName = "Mangueras";
  mangueras.Rect = [(width * 3) / 4, 67, 8, 8];
  pdf.addField(mangueras);
  var bombas = new AcroFormCheckBox();
  bombas.fieldName = "Bombas";
  bombas.Rect = [(width * 3) / 4, 79, 8, 8];
  pdf.addField(bombas);
  var filtros = new AcroFormCheckBox();
  filtros.fieldName = "Filtros";
  filtros.Rect = [(width * 3) / 4, 91, 8, 8];
  pdf.addField(filtros);
  pdf.text(["OK", "OK", "OK", "OK"], (width * 3) / 4 - 10, 62);

  //inspeccion de maquinas para el arranque
  var nivLlenado = new AcroFormCheckBox();
  nivLlenado.fieldName = "Nivel de llenado";
  nivLlenado.Rect = [width / 4, 135, 8, 8];
  pdf.addField(nivLlenado);
  var tapado = new AcroFormCheckBox();
  tapado.fieldName = "Tapado";
  tapado.Rect = [width / 4, 147, 8, 8];
  pdf.addField(tapado);
  var etiquetado = new AcroFormCheckBox();
  etiquetado.fieldName = "Etiquetado";
  etiquetado.Rect = [width / 4, 159, 8, 8];
  pdf.addField(etiquetado);
  var codificadoEnvase = new AcroFormCheckBox();
  codificadoEnvase.fieldName = "Condificado de envase";
  codificadoEnvase.Rect = [width / 4, 171, 8, 8];
  pdf.addField(codificadoEnvase);
  var codificadoCajas = new AcroFormCheckBox();
  codificadoCajas.fieldName = "Codificado cajas";
  codificadoCajas.Rect = [width / 4, 183, 8, 8];
  pdf.addField(codificadoCajas);
  pdf.text(["OK", "OK", "OK", "OK", "OK"], width / 4 - 10, 142);

  //analisis fisico-quimico
  var gradoAlcoholico = new AcroFormTextField();
  gradoAlcoholico.fieldName = "Grado Alcohólico Aparente";
  gradoAlcoholico.Rect = [(width * 3) / 4, 135, 50, 8];
  //gradoAlcoholico.value = data[14].value;
  gradoAlcoholico.value = "";
  pdf.addField(gradoAlcoholico);
  var dentroRango = new AcroFormCheckBox();
  dentroRango.fieldName = "Dentro del rango";
  dentroRango.Rect = [(width * 3) / 4, 147, 8, 8];
  pdf.addField(dentroRango);
  var ph = new AcroFormTextField();
  ph.fieldName = "pH";
  ph.Rect = [(width * 3) / 4, 159, 50, 8];
  //ph.value = data[16].value;

  pdf.addField(ph);
  var pruebaOrganoleptica = new AcroFormCheckBox();
  pruebaOrganoleptica.fieldName = "Prueba Organoléptica";
  pruebaOrganoleptica.Rect = [(width * 3) / 4, 171, 8, 8];
  pdf.addField(pruebaOrganoleptica);

  pdf.setFont("helvetica", "bold");
  pdf.text(["INFORMACIÓN DEL PRODUCTO"], width / 2, 210, { align: "center" });
  pdf.setFont("helvetica", "normal");
  // inf de suministro
  pdf.rect(20, 200, width - 40, 12);

  /***** etiquetas *****/
  pdf.text(
    [
      "HORA:",
      "Frente:",
      "Recetario:",
      "Cuello:",
      "Hombro:",
      "Ovalo:",
      "Strip:",
    ],
    30,
    230
  );

  // Etiquetas
  pdf.autoTable({
    head: [
      [
        "Inspección 1",
        "Inspección 2",
        "Inspección 3",
        "Inspección 4",
        "Inspección 5",
      ],
    ],
    body: [[], [], [], [], [], [], []],
    startY: 220,
    margin: { left: 150, right: 20 },
    styles: { fontSize: 8, cellPadding: 2 },
    theme: "grid",
    headStyles: { halign: "center", fillColor: [41, 128, 186] },
  });

  // Pegamento
  pdf.autoTable({
    head: [
      [
        "Inspección 1",
        "Inspección 2",
        "Inspección 3",
        "Inspección 4",
        "Inspección 5",
      ],
    ],
    body: [[], [], []],
    startY: 315,
    margin: { left: 150, right: 20 },
    styles: { fontSize: 8, cellPadding: 2 },
    theme: "grid",
    showHead: "never",
    headStyles: { halign: "center", fillColor: [41, 128, 186] },
  });

  var frente_Autoad = new AcroFormCheckBox();
  frente_Autoad.fieldName = "frente autoadhesivo";
  frente_Autoad.Rect = [70, 237, 8, 8];
  pdf.addField(frente_Autoad);
  var recetario_Autoad = new AcroFormCheckBox();
  recetario_Autoad.fieldName = "recetario autoadhesivo";
  recetario_Autoad.Rect = [70, 249, 8, 8];
  pdf.addField(recetario_Autoad);
  var cuello_Autoad = new AcroFormCheckBox();
  cuello_Autoad.fieldName = "cuello autoadhesivo";
  cuello_Autoad.Rect = [70, 261, 8, 8];
  pdf.addField(cuello_Autoad);
  var hombro_Autoad = new AcroFormCheckBox();
  hombro_Autoad.fieldName = "hombro autoadhesivo";
  hombro_Autoad.Rect = [70, 273, 8, 8];
  pdf.addField(hombro_Autoad);
  var ovalo_Autoad = new AcroFormCheckBox();
  ovalo_Autoad.fieldName = "ovalo autoadhesivo";
  ovalo_Autoad.Rect = [70, 285, 8, 8];
  pdf.addField(ovalo_Autoad);
  var strip_Autoad = new AcroFormCheckBox();
  strip_Autoad.fieldName = "strip autoadhesivo";
  strip_Autoad.Rect = [70, 297, 8, 8];
  pdf.addField(strip_Autoad);

  var frente_ColaFria = new AcroFormCheckBox();
  frente_ColaFria.fieldName = "frente ColaFria";
  frente_ColaFria.Rect = [100, 237, 8, 8];
  pdf.addField(frente_ColaFria);
  var recetario_ColaFria = new AcroFormCheckBox();
  recetario_ColaFria.fieldName = "recetario ColaFria";
  recetario_ColaFria.Rect = [100, 249, 8, 8];
  pdf.addField(recetario_ColaFria);
  var cuello_ColaFria = new AcroFormCheckBox();
  cuello_ColaFria.fieldName = "cuello ColaFria";
  cuello_ColaFria.Rect = [100, 261, 8, 8];
  pdf.addField(cuello_ColaFria);
  var hombro_ColaFria = new AcroFormCheckBox();
  hombro_ColaFria.fieldName = "hombro ColaFria";
  hombro_ColaFria.Rect = [100, 273, 8, 8];
  pdf.addField(hombro_ColaFria);
  var ovalo_ColaFria = new AcroFormCheckBox();
  ovalo_ColaFria.fieldName = "ovalo ColaFria";
  ovalo_ColaFria.Rect = [100, 285, 8, 8];
  pdf.addField(ovalo_ColaFria);
  var strip_ColaFria = new AcroFormCheckBox();
  strip_ColaFria.fieldName = "strip ColaFria";
  strip_ColaFria.Rect = [100, 297, 8, 8];
  pdf.addField(strip_ColaFria);

  pdf.text(
    [
      "Autoad.     Cola Fría",
      "Autoad.     Cola Fría",
      "Autoad.     Cola Fría",
      "Autoad.     Cola Fría",
      "Autoad.     Cola Fría",
      "Autoad.     Cola Fría",
    ],
    80,
    244
  );

  /***** pegamento *****/
  pdf.text(["Tipo de Goma:", "Fecha de Prod.", "Fecha de Exp."], 30, 320);

  pdf.line(20, 360, width - 20, 360);

  /**** caja de carton corrugado ****/
  pdf.text(
    [
      "Proveedor",
      "Fecha de Prod. G:Grapas",
      "Sellado: G/CA  CA: Cinta Adhesiva",
    ],
    30,
    370
  );

  // Caja de cartón
  pdf.autoTable({
    head: [
      [
        "Inspección 1",
        "Inspección 2",
        "Inspección 3",
        "Inspección 4",
        "Inspección 5",
      ],
    ],
    body: [[], []],
    startY: 365,
    margin: { left: 150, right: 20 },
    styles: { fontSize: 8, cellPadding: 2 },
    theme: "grid",
    showHead: "never",
    headStyles: { halign: "center", fillColor: [41, 128, 186] },
  });

  pdf.text("G           CA", (width * 2) / 8 + 50, 396);
  pdf.text("G           CA", (width * 3) / 8 + 50, 396);
  pdf.text("G           CA", (width * 4) / 8 + 50, 396);
  pdf.text("G           CA", (width * 5) / 8 + 50, 396);
  pdf.text("G           CA", (width * 6) / 8 + 50, 396);

  var g1 = new AcroFormCheckBox();
  g1.fieldName = "g1";
  g1.Rect = [(width * 2) / 8 + 40, 389, 8, 8];
  pdf.addField(g1);
  var g2 = new AcroFormCheckBox();
  g2.fieldName = "g2";
  g2.Rect = [(width * 3) / 8 + 40, 389, 8, 8];
  pdf.addField(g2);
  var g3 = new AcroFormCheckBox();
  g3.fieldName = "g3";
  g3.Rect = [(width * 4) / 8 + 40, 389, 8, 8];
  pdf.addField(g3);
  var g4 = new AcroFormCheckBox();
  g4.fieldName = "g4";
  g4.Rect = [(width * 5) / 8 + 40, 389, 8, 8];
  pdf.addField(g4);
  var g5 = new AcroFormCheckBox();
  g5.fieldName = "g5";
  g5.Rect = [(width * 6) / 8 + 40, 389, 8, 8];
  pdf.addField(g5);

  var ca1 = new AcroFormCheckBox();
  ca1.fieldName = "ca1";
  ca1.Rect = [(width * 2) / 8 + 65, 389, 8, 8];
  pdf.addField(ca1);
  var ca2 = new AcroFormCheckBox();
  ca2.fieldName = "ca2";
  ca2.Rect = [(width * 3) / 8 + 65, 389, 8, 8];
  pdf.addField(ca2);
  var ca3 = new AcroFormCheckBox();
  ca3.fieldName = "ca3";
  ca3.Rect = [(width * 4) / 8 + 65, 389, 8, 8];
  pdf.addField(ca3);
  var ca4 = new AcroFormCheckBox();
  ca4.fieldName = "ca4";
  ca4.Rect = [(width * 5) / 8 + 65, 389, 8, 8];
  pdf.addField(ca4);
  var ca5 = new AcroFormCheckBox();
  ca5.fieldName = "ca5";
  ca5.Rect = [(width * 6) / 8 + 65, 389, 8, 8];
  pdf.addField(ca5);

  pdf.line(20, 410, width - 20, 410);

  /***** tapas *****/
  pdf.text(
    [
      "Modelo:",
      "NR / OP:    NR: Irrellenable",
      "Fecha de Prod.:  OP: Rellenable",
      "Proveedor:",
    ],
    30,
    420
  );

  // Tapas Modelo
  pdf.autoTable({
    head: [
      [
        "Inspección 1",
        "Inspección 2",
        "Inspección 3",
        "Inspección 4",
        "Inspección 5",
      ],
    ],
    body: [[]],
    startY: 415,
    margin: { left: 150, right: 20 },
    styles: { fontSize: 8, cellPadding: 2 },
    theme: "grid",
    showHead: "never",
    headStyles: { halign: "center", fillColor: [41, 128, 186] },
  });

  pdf.text("NR         OP", (width * 2) / 8 + 50, 434);
  pdf.text("NR         OP", (width * 3) / 8 + 50, 434);
  pdf.text("NR         OP", (width * 4) / 8 + 50, 434);
  pdf.text("NR         OP", (width * 5) / 8 + 50, 434);
  pdf.text("NR         OP", (width * 6) / 8 + 50, 434);

  var nr1 = new AcroFormCheckBox();
  nr1.fieldName = "nr1";
  nr1.Rect = [(width * 2) / 8 + 40, 427, 8, 8];
  pdf.addField(nr1);
  var nr2 = new AcroFormCheckBox();
  nr2.fieldName = "nr2";
  nr2.Rect = [(width * 3) / 8 + 40, 427, 8, 8];
  pdf.addField(nr2);
  var nr3 = new AcroFormCheckBox();
  nr3.fieldName = "nr3";
  nr3.Rect = [(width * 4) / 8 + 40, 427, 8, 8];
  pdf.addField(nr3);
  var nr4 = new AcroFormCheckBox();
  nr4.fieldName = "nr4";
  nr4.Rect = [(width * 5) / 8 + 40, 427, 8, 8];
  pdf.addField(nr4);
  var nr5 = new AcroFormCheckBox();
  nr5.fieldName = "nr5";
  nr5.Rect = [(width * 6) / 8 + 40, 427, 8, 8];
  pdf.addField(nr5);

  var op1 = new AcroFormCheckBox();
  op1.fieldName = "op1";
  op1.Rect = [(width * 2) / 8 + 65, 427, 8, 8];
  pdf.addField(op1);
  var op2 = new AcroFormCheckBox();
  op2.fieldName = "op2";
  op2.Rect = [(width * 3) / 8 + 65, 427, 8, 8];
  pdf.addField(op2);
  var op3 = new AcroFormCheckBox();
  op3.fieldName = "op3";
  op3.Rect = [(width * 4) / 8 + 65, 427, 8, 8];
  pdf.addField(op3);
  var op4 = new AcroFormCheckBox();
  op4.fieldName = "op4";
  op4.Rect = [(width * 5) / 8 + 65, 427, 8, 8];
  pdf.addField(op4);
  var op5 = new AcroFormCheckBox();
  op5.fieldName = "op5";
  op5.Rect = [(width * 6) / 8 + 65, 427, 8, 8];
  pdf.addField(op5);

  // Tapas Fecha Proveedor
  pdf.autoTable({
    head: [
      [
        "Inspección 1",
        "Inspección 2",
        "Inspección 3",
        "Inspección 4",
        "Inspección 5",
      ],
    ],
    body: [[], []],
    startY: 440,
    margin: { left: 150, right: 20 },
    styles: { fontSize: 8, cellPadding: 2 },
    theme: "grid",
    showHead: "never",
    headStyles: { halign: "center", fillColor: [41, 128, 186] },
  });

  pdf.line(20, 470, width - 20, 470);

  /***** tipo de envase *****/
  pdf.text(
    [
      "VR / PET / Otros:  VR: Vidrio Reciclado",
      "Fecha de Prod. (VN)  VN: Vidrio Nuevo",
      "Proveedor:",
      "Tipo de lavado:",
    ],
    30,
    480
  );

  // Tipo de envases
  pdf.autoTable({
    head: [
      [
        "Inspección 1",
        "Inspección 2",
        "Inspección 3",
        "Inspección 4",
        "Inspección 5",
      ],
    ],
    body: [[], [], []],
    startY: 475,
    margin: { left: 150, right: 20 },
    styles: { fontSize: 8, cellPadding: 2 },
    theme: "grid",
    showHead: "never",
    headStyles: { halign: "center", fillColor: [41, 128, 186] },
  });

  pdf.text("E            L", (width * 2) / 8 + 50, 518);
  pdf.text("E            L", (width * 3) / 8 + 50, 518);
  pdf.text("E            L", (width * 4) / 8 + 50, 518);
  pdf.text("E            L", (width * 5) / 8 + 50, 518);
  pdf.text("E            L", (width * 6) / 8 + 50, 518);

  var e1 = new AcroFormCheckBox();
  e1.fieldName = "e1";
  e1.Rect = [(width * 2) / 8 + 40, 511, 8, 8];
  pdf.addField(e1);
  var e2 = new AcroFormCheckBox();
  e2.fieldName = "e2";
  e2.Rect = [(width * 3) / 8 + 40, 511, 8, 8];
  pdf.addField(e2);
  var e3 = new AcroFormCheckBox();
  e3.fieldName = "e3";
  e3.Rect = [(width * 4) / 8 + 40, 511, 8, 8];
  pdf.addField(e3);
  var e4 = new AcroFormCheckBox();
  e4.fieldName = "e4";
  e4.Rect = [(width * 5) / 8 + 40, 511, 8, 8];
  pdf.addField(e4);
  var e5 = new AcroFormCheckBox();
  e5.fieldName = "e5";
  e5.Rect = [(width * 6) / 8 + 40, 511, 8, 8];
  pdf.addField(e5);

  var l1 = new AcroFormCheckBox();
  l1.fieldName = "l1";
  l1.Rect = [(width * 2) / 8 + 65, 511, 8, 8];
  pdf.addField(l1);
  var l2 = new AcroFormCheckBox();
  l2.fieldName = "l2";
  l2.Rect = [(width * 3) / 8 + 65, 511, 8, 8];
  pdf.addField(l2);
  var l3 = new AcroFormCheckBox();
  l3.fieldName = "l3";
  l3.Rect = [(width * 4) / 8 + 65, 511, 8, 8];
  pdf.addField(l3);
  var l4 = new AcroFormCheckBox();
  l4.fieldName = "l4";
  l4.Rect = [(width * 5) / 8 + 65, 511, 8, 8];
  pdf.addField(l4);
  var l5 = new AcroFormCheckBox();
  l5.fieldName = "l5";
  l5.Rect = [(width * 6) / 8 + 65, 511, 8, 8];
  pdf.addField(l5);

  pdf.setFontSize(6);
  pdf.setLineHeightFactor(1.15);
  pdf.text("Etiquetas", 15, 270, { angle: 90 });
  pdf.text("Proveedor / OT y/o OC", 140, 270, { angle: 90, maxWidth: 30 });
  pdf.text("Pegamento", 15, 350, { angle: 90, maxWidth: 50 });
  pdf.text("Caja de Cartón Corrugado", 15, 400, { angle: 90, maxWidth: 50 });
  pdf.text("Tapas", 15, 450, { angle: 90, maxWidth: 50 });
  pdf.text("Tipo de envase", 15, 500, { angle: 90, maxWidth: 30 });

  pdf.setFontSize(8);
  pdf.line(20, 530, width - 20, 530);
  pdf.setLineHeightFactor(2);
  pdf.text(
    [
      "Concentración de ácido cítrico:",
      "pH antes de lavado los envases",
      "pH después de lavado los envases",
    ],
    20,
    550
  );

  // Acido
  pdf.autoTable({
    head: [
      [
        "Inspección 1",
        "Inspección 2",
        "Inspección 3",
        "Inspección 4",
        "Inspección 5",
      ],
    ],
    body: [[], [], []],
    startY: 545,
    margin: { left: 150, right: 20 },
    styles: { fontSize: 8, cellPadding: 2 },
    theme: "grid",
    showHead: "never",
    headStyles: { halign: "center", fillColor: [41, 128, 186] },
  });

  /*******************************/
  /*            pag 2            */
  /*******************************/
  pdf.addPage();
  pdf.setLineHeightFactor(2);
  pdf.setFontSize(14);
  pdf.text("INSPECCIÓN DE LÍNEA", width / 2, 20, { align: "center" });
  pdf.text("Varela Hermanos, S.A.", width / 2, 30, { align: "center" });
  pdf.addImage(logo, "JPEG", 20, 10, 30, 25);
  pdf.line(20, 35, width - 20, 35);
  pdf.setFontSize(8);
  //calculo del nivel de llenado
  pdf.text("CALCULO DEL NIVEL DE LLENADO", width / 2, 50, { align: "center" });
  pdf.rect(20, 40, width - 40, 12);
  pdf.text("Inspección 1", (width * 2) / 8 + 50, 60, { align: "left" });
  pdf.text("Inspección 2", (width * 3) / 8 + 50, 60, { align: "left" });
  pdf.text("Inspección 3", (width * 4) / 8 + 50, 60, { align: "left" });
  pdf.text("Inspección 4", (width * 5) / 8 + 50, 60, { align: "left" });
  pdf.text("Inspección 5", (width * 6) / 8 + 50, 60, { align: "left" });

  pdf.text(
    [
      "Tanque:",
      "Lote:",
      "Día de Preparación:",
      "Prueba Organoléptica:",
      "Horas de Reposo:",
      "Alcoholímetro:",
      "Temperatura:",
      "Grado Alcohólico:",
      "pH:",
      "Línea / T. de Licor:",
      "Destino:",
      "Balanza No de Serie.:",
      "Peso de Tapas:",
      "Peso de Envases Vacíos:",
      "Peso de Envases LLenos:",
      "Peso Neto Actual:",
      "Peso Ideal:",
      "Dif. Peso Neto",
      "Límites:",
      "Dif. Porcentual:",
    ],
    20,
    70
  );

  // Calculo de nivel de llenado
  pdf.autoTable({
    head: [
      [
        "Inspección 1",
        "Inspección 2",
        "Inspección 3",
        "Inspección 4",
        "Inspección 5",
      ],
    ],
    body: [
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
    ],
    startY: 53,
    margin: { left: 150, right: 20 },
    styles: { fontSize: 8, cellPadding: 2.5 },
    theme: "grid",
    headStyles: { halign: "center", fillColor: [41, 128, 186] },
  });

  //personal de linea
  pdf.text("PERSONAL DE LÍNEA", width / 2, 320, { align: "center" });
  pdf.rect(20, 310, width - 40, 12);

  pdf.setLineHeightFactor(2);
  pdf.text(
    [
      "1) Enjuag./Lavadora",
      "2) 1ra Inspección",
      "3) LLenadora",
      "4) 2da. Inspección",
      "5)Tapadora",
      "6) Etiquetadora",
      "7) Mesa de Inspección",
      "8) Recogiendo",
      "9) Estibando",
    ],
    20,
    340
  );

  // Personal de Linea
  pdf.autoTable({
    head: [
      [
        "Inspección 1",
        "Inspección 2",
        "Inspección 3",
        "Inspección 4",
        "Inspección 5",
      ],
    ],
    body: [[], [], [], [], [], [], [], [], []],
    startY: 323,
    margin: { left: 150, right: 20 },
    styles: { fontSize: 8, cellPadding: 2.5 },
    theme: "grid",
    headStyles: { halign: "center", fillColor: [41, 128, 186] },
  });

  pdf.text("Observaciones:", 20, 460);
  var observaciones = new AcroFormTextField();
  observaciones.fieldName = "observaciones";
  observaciones.Rect = [80, 455, width - 100, 24];
  pdf.addField(observaciones);

  pdf.text("Inspector (a):", 20, 500);
  pdf.text("Aseguramiento de la Calidad", 65, 508);
  pdf.line(60, 502, 150, 502);

  pdf.text("Verificado:", width / 3 + 20, 500, { align: "left" });
  pdf.text("Sup. de Aseguramiento de la Calidad", width / 3 + 55, 508);
  pdf.line(width / 3 + 50, 502, width / 3 + 160, 502);

  pdf.text("Informado:", (width * 2) / 3 + 30, 500, { align: "left" });
  pdf.text("Operaciones", (width * 2) / 3 + 70, 508);
  pdf.line((width * 2) / 3 + 60, 502, (width * 2) / 3 + 120, 502);

  //uncheck checkboxes
  lineaTrasiego.appearanceState = "Off";
  mangueras.appearanceState = "Off";
  bombas.appearanceState = "Off";
  filtros.appearanceState = "Off";
  nivLlenado.appearanceState = "Off";
  tapado.appearanceState = "Off";
  etiquetado.appearanceState = "Off";
  codificadoEnvase.appearanceState = "Off";
  codificadoCajas.appearanceState = "Off";
  dentroRango.appearanceState = "Off";
  pruebaOrganoleptica.appearanceState = "Off";
  frente_Autoad.appearanceState = "Off";
  recetario_Autoad.appearanceState = "Off";
  cuello_Autoad.appearanceState = "Off";
  hombro_Autoad.appearanceState = "Off";
  ovalo_Autoad.appearanceState = "Off";
  strip_Autoad.appearanceState = "Off";
  frente_ColaFria.appearanceState = "Off";
  recetario_ColaFria.appearanceState = "Off";
  cuello_ColaFria.appearanceState = "Off";
  hombro_ColaFria.appearanceState = "Off";
  ovalo_ColaFria.appearanceState = "Off";
  strip_ColaFria.appearanceState = "Off";
  g1.appearanceState = "Off";
  g2.appearanceState = "Off";
  g3.appearanceState = "Off";
  g4.appearanceState = "Off";
  g5.appearanceState = "Off";
  ca1.appearanceState = "Off";
  ca2.appearanceState = "Off";
  ca3.appearanceState = "Off";
  ca4.appearanceState = "Off";
  ca5.appearanceState = "Off";
  nr1.appearanceState = "Off";
  nr2.appearanceState = "Off";
  nr3.appearanceState = "Off";
  nr4.appearanceState = "Off";
  nr5.appearanceState = "Off";
  op1.appearanceState = "Off";
  op2.appearanceState = "Off";
  op3.appearanceState = "Off";
  op4.appearanceState = "Off";
  op5.appearanceState = "Off";
  e1.appearanceState = "Off";
  e2.appearanceState = "Off";
  e3.appearanceState = "Off";
  e4.appearanceState = "Off";
  e5.appearanceState = "Off";
  l1.appearanceState = "Off";
  l2.appearanceState = "Off";
  l3.appearanceState = "Off";
  l4.appearanceState = "Off";
  l5.appearanceState = "Off";

  /* guardar formulario */
  //pdf.save('testForm.pdf')
  return pdf.output("bloburl");
}

//export default PdfLine;
