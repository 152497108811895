import React from 'react'
import {
    TextField, Grid, FormControlLabel,
    FormGroup, Checkbox, Button
} from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition"
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { red, blue } from '@mui/material/colors';

export default function Observaciones() {
    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition();

    if (!browserSupportsSpeechRecognition) {
        return (
        <div>
            <span>El navegador no es compatible con el reconocimiento de voz.</span>
            <Grid item xl={12} xs={12}>
                <TextField fullWidth multiline rows={3}/>
            </Grid>
        </div>
        )
    }
    return (
        <Grid container>
            <Grid item xl={12} xs={12}>
                <p>Micrófono: {listening ? "Encendido" : "Apagado"}</p>
                <Button onClick={listening ? SpeechRecognition.stopListening : SpeechRecognition.startListening}>{listening ? (<MicIcon sx={{ color: red[500] }} />) : (<MicIcon sx={{ color: blue[500] }} />)}</Button>
                <Button onClick={resetTranscript}><RestartAltIcon color="action" /></Button>
                <TextField fullWidth multiline rows={3} value={transcript} />
            </Grid>
            <Grid item xl={12} xs={12}>
                <FormGroup>
                    <FormControlLabel control={<Checkbox />} label="Sin observaciones" />
                </FormGroup>
            </Grid>
        </Grid>
    )
}