import React from 'react'
import {
    TextField, Typography, Grid, FormControl, Radio, RadioGroup, FormControlLabel, FormLabel  } from '@mui/material';
import info from './temp.json'

export default function AnalisisFQ() {
    return (
        <Grid container spacing={4}>
            <Grid item xs={12} xl={3} sm={6} md={4}>
                <Typography>{info[14].field_name}</Typography>
                <TextField fullWidth value={info[14].value} />
            </Grid>
            <Grid item xs={12} xl={3} sm={6} md={4}>
                <FormControl>
                    <FormLabel required id="filtros">Dentro del rango</FormLabel>
                    <RadioGroup
                        row
                        value={info[15].value}
                    >
                        <FormControlLabel value="ok" control={<Radio />} label="OK" />
                        <FormControlLabel value="no ok" control={<Radio />} label="No OK" />
                    </RadioGroup>
                </FormControl>
            </Grid>
            <Grid item xs={12} xl={3} sm={6} md={4}>
                <Typography>pH</Typography>
                <TextField fullWidth value={info[16].value}/>
            </Grid>
            <Grid item xs={12} xl={3} sm={6} md={4}>
                <FormControl>
                    <FormLabel required id="filtros">Prueba Organoléptica</FormLabel>
                    <RadioGroup
                        row
                        value={info[17].value}
                    >
                        <FormControlLabel value="ok" control={<Radio />} label="OK" />
                        <FormControlLabel value="no ok" control={<Radio />} label="No OK" />
                    </RadioGroup>
                </FormControl>
            </Grid>
        </Grid>
    )
}
