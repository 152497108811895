/**
 * Component Menu New Inspection
 * @author: Luis Avello
 * @author: Vicente Illanes // 10.05.2022
 * @version 1.1.0
 * @updated 2023-03-22
 */

import React, { Component } from "react";
import "../../styles/menu.css";
import "../../styles/inspection_form/tab.css";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { IconButton, Tooltip } from "@mui/material";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import DeleteIcon from "@mui/icons-material/Delete";

class MenuNewInspection extends Component {
  /**
   * Captura evento de creación de nueva inspección
   */
  onAddNewInspection = () => {
    this.props.addNewInspection();
  };

  onDeleteLastInspection = () => {
    console.log("- Delete Last Inspection");
  };

  /**
   * Setea la inspeccion actual a partir de un indice
   * @param {Integer} index
   */
  onSetCurrentInspection = (event, index) => {
    this.props.setCurrentInspection(index);
  };
  render() {
    return (
      <Grid
        container
        spacing={{ xs: 2, md: 3, md: 3, lg: 3 }}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
        sx={{ margin: "5px" }}
      >
        <Grid item xs={10} sm={10} md={10} lg={10}>
          <Tabs
            value={this.props.active_inspection}
            onChange={this.onSetCurrentInspection}
            variant="scrollable"
            scrollButtons
          >
            {Array(this.props.form.number_of_inspections)
              .fill("Inspección N°")
              .map((preffix, i) => {
                return (
                  <Tab value={i + 1} label={`${preffix}${i + 1}`} key={i} />
                );
              })}
          </Tabs>
        </Grid>
        <Grid item xs={2} sm={2} md={2} lg={2}>
          <Tooltip title="Agregar Inspección">
            <IconButton onClick={() => this.onAddNewInspection()}>
              <AddCircleRoundedIcon color="success" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Eliminar Inspección">
            <IconButton onClick={() => this.onDeleteLastInspection()}>
              <DeleteIcon color="error" />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    );
  }
}

export default MenuNewInspection;
