/**
 * Component Create Ticket
 * @author: Luis Avello
 * @version 1.0.0
 * @updated 2022-09-25
 */
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const NewTicket = (props) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container maxWidth="false">
      <Button variant="contained" onClick={handleClickOpen}>
        Nuevo Ticket
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Nuevo Ticket</DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <TextField
            id="line"
            label="Linea"
            variant="standard"
            defaultValue="Linea 1"
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            id="order"
            label="Orden"
            variant="standard"
            defaultValue="105300007553"
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            id="pallet"
            label="Pallet"
            variant="standard"
            defaultValue="7553-1"
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            id="traza"
            label="Trazabilidad"
            variant="standard"
            defaultValue="L260213RA0817 000016"
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            id="product"
            label="Producto"
            variant="standard"
            defaultValue="100046 | R.ABUELO 12 AÑOS 1000ML-VC-37.5-6U CL"
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            id="inspect"
            label="Inspección"
            variant="standard"
            defaultValue="2"
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            id="critico"
            label="Defecto Crítico"
            variant="standard"
            defaultValue=""
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            id="mayor"
            label="Defecto Mayor"
            variant="standard"
            defaultValue=""
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            id="menor"
            label="Defecto Menor"
            variant="standard"
            defaultValue=""
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            id="inspect"
            label="Cantidad Inspeccionada (unid)"
            type="number"
            variant="standard"
            defaultValue="0"
            autoFocus
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="inspect"
            label="Cantidad No Conforme (unid)"
            type="number"
            variant="standard"
            defaultValue="0"
            autoFocus
            InputLabelProps={{
              shrink: true,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleClose} variant="contained">
            Crear
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default NewTicket;
