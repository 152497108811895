import React from "react";
import "../../styles/modal.css";
import "../../styles/alert.css";

class TableForms extends React.Component {
  state = {
    display_model: "none",
    currentForm: {}
  }
  goToForm = (form) => {
    window.location = `/inspection_form/edit?id=${form.id}&order_id=${form.order.id}`;
  }
  openModal = (form) => {
    this.setState({display_model: "block", currentForm: form});
  }
  closeModal = () => {
    this.setState({display_model: 'none', currentForm: {}});
  }
  goToDelete = () => {
    this.props.deleteForm(this.state.currentForm.id);
    this.closeModal();
  }
  getCompleteClassAlert = () => {
    if (this.props.msg === "OK"){
        return "alert alert-success"
    } else {
        return "alert alert-danger"
    }
  }
  getCompleteMessageAlert = () =>{
    if (this.props.msg === "OK"){
        return "Formulario Eliminado Exitosamente."
    } else {
        return "Error al tratar de eliminar Formulario."
    }
  }
  render () {
    return(
        <div>
            <h6>{this.props.title}</h6>
            {this.props.msg !== "" ?
                <div className={this.getCompleteClassAlert()}>
                    <span className="closebtn" onClick={() => this.props.closeAlert()}>&times;</span> 
                    {this.getCompleteMessageAlert()}
                </div> : null
            }
            {this.props.forms.length > 0 ?
            <>
            <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Formulario</th>
                        <th>Inspector</th>
                        <th>Fecha</th>
                        <th>%</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.forms.map((form_data, i) => {
                        
                        return (
                            <tr key={i}>
                                <td>{form_data.id}</td>
                                <td>{form_data.name}</td>
                                <td>{form_data.inspector}</td>
                                <td>{form_data.date}</td>
                                <td>{form_data.porcentage}%</td>
                                <td><button onClick={() => this.goToForm(form_data)}> Ver </button></td>
                                <td>
                                    {form_data.is_ready ? 
                                        null :
                                        <button onClick={() => this.openModal(form_data)}> Eliminar </button>
                                    }
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <div className="modal-delete-form" style={{display: this.state.display_model}}>
              <div className="modal-content-delete-form">
                <span className="close-delete-form" onClick={() => this.closeModal()}>&times;</span>
                <p>¿Esta seguro de eliminar el formulario de <b>{this.state.currentForm.name}</b>?</p>
                <button 
                  className="mdl-button mdl-button--colored mdl-js-button mdl-js-ripple-effect"
                  onClick={() => this.goToDelete()}
                >Eliminar</button>
                <button 
                  className="mdl-button mdl-button--colored mdl-js-button mdl-js-ripple-effect"
                  onClick={() => this.closeModal()}
                > Cancelar</button>
              </div>
            </div>
            </> : "No hay registros"
            }
        </div>
    );
  }
}
export default TableForms
