/**
 * Component PdfTrop
 * @author: Luis Avello
 * @version 1.3.1
 * @updated 2023-07-18
 */
import jsPDF, { AcroFormCheckBox, AcroFormTextField } from "jspdf";
import logo from "../imgs/cilindros.jpg";
import "jspdf-autotable";

function addCheckBox(field, pdf, fontSize, checked) {
  field.readOnly = true;
  field.fontSize = fontSize;
  let appearanceState = "Off";
  if (checked) appearanceState = "On";
  field.appearanceState = appearanceState;
  pdf.addField(field);
}

export default function PdfTrop(data) {
  const pdf = new jsPDF({ unit: "px", pageSize: "letter" });
  var width = pdf.internal.pageSize.getWidth();
  console.log("Ejecutando PdfLine");
  pdf.setFont("helvetica", "bold");
  pdf.setFontSize(11);

  let fontsize_table = 7;

  //var height = pdf.internal.pageSize.getHeight()
  pdf.setLineHeightFactor(1.8);

  pdf.text(["Control de Producto no Conforme"], width / 2, 20, {
    align: "center",
  });
  pdf.text("Industria Panameña de Cilindros, S.A", width / 2, 30, {
    align: "center",
  });
  pdf.addImage(logo, "JPEG", 20, 10, 30, 25);
  pdf.line(10, 35, width - 10, 35);

  pdf.setFontSize(9);
  pdf.text(
    [data.header.form_code, data.header.revision, data.header.updated],
    430,
    12,
    {
      align: "right",
      lineHeightFactor: 1.25,
    }
  );

  const num_inspections = data.body.MAIN.DATE.VALUES.length;
  //informacion de operaciones
  pdf.setFontSize(8);
  pdf.setLineHeightFactor(2.0);
  pdf.setFont("helvetica", "normal");
  pdf.text(
    [
      "Fecha",
      "Hora",
      "Estación / Proceso",
      "Encargado",
      "Motivo",
      "Cantidad",
      "Detalle",
      "Disposición",
      "PNC Recuperado",
      "Observaciones",
    ],
    10,
    70
  );

  // Etiquetas
  pdf.autoTable({
    head: [["NC 1", "NC 2", "NC 3", "NC 4", "NC 5", "NC 6", "NC 7", "NC 8"]],
    body: [
      data.body.MAIN.DATE.VALUES,
      data.body.MAIN.HOUR.VALUES,
      data.body.MAIN.STATION_PROCESS.VALUES,
      data.body.MAIN.PERSON_IN_CHARGE.VALUES,
      data.body.MAIN.REASON.VALUES,
      data.body.MAIN.QUANTITY.VALUES,
      data.body.MAIN.DETAIL.VALUES,
      data.body.MAIN.LAYOUT.VALUES_ALT,
      data.body.MAIN.PNC_RECOVERED.VALUES,
      data.body.MAIN.OBSERVATIONS.VALUES,
    ],
    startY: 50,
    margin: { left: 65, right: 15 },
    styles: { fontSize: 8, cellPadding: 2.502 },
    theme: "grid",
    headStyles: { halign: "center", fillColor: [41, 128, 186] },
  });

  /* guardar formulario */
  //pdf.save('testForm.pdf')
  return pdf.output("bloburl");
}

//export default PdfLine;
