/**
 * Component FormResume
 * @author: Luis Avello
 * @version 1.0.0
 * @updated 2023-08-17
 */
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import KeyIcon from "@mui/icons-material/Key";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Tooltip from "@mui/material/Tooltip";

import Box from "@mui/material/Box";
import { DataGrid, GridToolbar, esES } from "@mui/x-data-grid";

import { red } from "@mui/material/colors";
import { purple } from "@mui/material/colors";
import { indigo } from "@mui/material/colors";

import dayjs from "dayjs";
import "dayjs/locale/es";

const FormListTrop = (props) => {
  const { dummyMode = false } = props;
  const [dateStart, setDateStart] = React.useState(dayjs());
  const [dateEnd, setDateEnd] = React.useState(dayjs());
  const [type, setType] = React.useState(0);
  const [types, setTypes] = React.useState([]);
  const [state, setState] = React.useState(0);
  const [states, setStates] = React.useState([]);
  const [line, setLine] = React.useState(0);
  const [lines, setLines] = useState([]);
  const [forms, setForms] = React.useState([]);
  const [form, setForm] = React.useState({});
  const [showDetail, setShowDetail] = React.useState(false);
  const full_width = 12;
  const compact_width = 8;
  const [tableWidth, setTableWidth] = React.useState(full_width);
  const [order, setOrder] = React.useState({ id: 0, line_id: 0 });

  const urlForms = "/api/react/v1/quality/forms";
  const urlLines = "/api/react/v1/quality/lines";
  const urlStates = "/api/react/v1/quality/states";
  const urlTypes = "/api/react/v1/quality/types";

  const urlSingleOrder = "/api/react/v1/quality/single_order";

  const color_edit = indigo[500];
  const color_pdf = red[600];
  const color_sign = purple[700];

  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);

  const handleClickedRow = (params) => {
    console.log("Row Clicked " + params.id);
    let [row] = forms.filter((item) => item.id == params.id);
    console.log("Row Product " + row.product);
    setForm(row);
    setShowDetail(true);
    setTableWidth(compact_width);
  };

  const handleCloseDetail = () => {
    setShowDetail(false);
    setTableWidth(full_width);
  };

  const handleClickPdf = (formmain_id) => {
    console.log("Pdf " + formmain_id);
    window.location = "/quality/pdf?formmain_id=" + formmain_id;
  };

  const handleClickEdit = (formmain_id) => {
    console.log("Edit " + formmain_id);
    window.location = "/quality/form?formmain_id=" + formmain_id;
  };

  const handleClickSign = (formmain_id) => {
    console.log("Sign " + formmain_id);
  };

  const handleNewForm = () => {
    let code = "TROPIGAS";
    console.log("New Form " + code);
    window.location =
      "/quality/form?code=" +
      code +
      "&line_id=" +
      order.line_id +
      "&order_id=" +
      order.id;
  };

  useEffect(() => {
    if (dummyMode) {
      console.log("FormList >> Dummy Mode");
    } else {
      // Get States
      // Get types
      // Get lines
      // Get single order
      // Get forms
    }
  }, [dateStart, dateEnd, line, state, type]);

  const columns = [
    { field: "id", headerName: "id", width: 100 },
    {
      field: "date",
      headerName: "Fecha",
      width: 150,
    },
    {
      field: "hour",
      headerName: "Hora",
      width: 150,
    },
    {
      field: "station_process",
      headerName: "Estación / Proceso",
      width: 150,
    },
    {
      field: "person_in_charge",
      headerName: "Encargado",
      width: 150,
    },
    {
      field: "reason",
      headerName: "Motivo",
      width: 150,
    },
    {
      field: "quantity",
      headerName: "Cantidad",
      width: 150,
    },
    {
      field: "detail",
      headerName: "Detalle",
      width: 150,
    },
    {
      field: "layout",
      headerName: "Disposición",
      width: 150,
    },
    {
      field: "pnc_recovered",
      headerName: "PNC Recuperado",
      width: 150,
    },
    {
      field: "observations",
      headerName: "Observaciones",
      width: 150,
    },
  ];

  return (
    <Container maxWidth="true" sx={{ pt: 2 }}>
      <Grid container spacing={1}>
        <Grid sm={tableWidth} md={tableWidth} xs={12}>
          <DataGrid
            rows={forms}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5]}
            slots={{ toolbar: GridToolbar }}
            onRowClick={handleClickedRow}
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            disableRowSelectionOnClick
          />
        </Grid>
        {showDetail ? (
          <Grid sm={4} md={4} xs={12}>
            <FormDetail form={form} handleClose={handleCloseDetail} />
          </Grid>
        ) : null}
      </Grid>
    </Container>
  );
};

export default FormListTrop;
