import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function BaseSelector(props) {
  const [value, setValue] = React.useState(props.defaultValue);

  const handleChange = (event) => {
    let val = event.target.value;
    setValue(event.target.value);
    props.onChangeValue(val);
  };
  let label_id = `${props.label}_id`;
  return (
    <FormControl sx={{ minWidth: 200 }} size="small">
      <InputLabel id={label_id}> { props.label } </InputLabel>
      <Select
        labelId={label_id}
        id={label_id}
        value={value}
        label={props.label}
        onChange={handleChange}
      >
        {props.needNone ? <MenuItem value="">
                            <em>None</em>
                          </MenuItem> : null }
        {props.options.map(opt => {
            return (
                <MenuItem value={opt.id} key={opt.id}>{opt.label}</MenuItem>
            )
        })}
      </Select>
    </FormControl>
  );
}
