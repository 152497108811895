import React from 'react'
import { Grid, FormControl, Radio, RadioGroup, FormControlLabel, FormLabel } from '@mui/material';
import info from './temp.json'

export default function SistemaTrasiego() {

  const data = [{ field: info[5].field_name, val: info[5].value },
    { field: info[6].field_name, val: info[6].value },
    { field: info[7].field_name, val: info[7].value },
    { field: info[8].field_name, val: info[8].value }]

  return (
      <Grid container spacing={6}>
        {data.map((item) => (
          <Grid item xs={12} xl={3} sm={6} md={4}>
          <FormControl>
            <FormLabel required>{item.field}</FormLabel>
            <RadioGroup
              row
              value={'ok'}
            >
              <FormControlLabel value="ok" control={<Radio />} label="OK" />
              <FormControlLabel value="no ok" control={<Radio />} label="No OK" />
            </RadioGroup>
          </FormControl>
        </Grid>
        ))}        
      </Grid>
  )
}
