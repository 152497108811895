export const findItem = (list, key, value) => {
  for (var i = 0; i < list.length; i++) {
    var item = list[i];
    if (item[key] == value) return item;
  }
  return null;
};

export const findItemKeys = (list, keys, values) => {
  for (var i = 0; i < list.length; i++) {
    var item = list[i];
    var cond = true;
    for (var j = 0; j < keys.length; j++) {
      cond = cond && item[keys[j]] == values[j];
    }
    if (cond) {
      return item;
    }
  }
  return null;
};
