/**
 * Component QualityView
 * @author: Luis Avello
 * @version 1.1.0
 * @updated 2023-04-20
 */
import React, { useEffect, useState } from "react";
import axios from "axios";
import Grid from "@mui/material/Unstable_Grid2";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import ListIcon from "@mui/icons-material/List";
import AddIcon from "@mui/icons-material/Add";
import QualityLineItem from "./quality_line_item";
import PlantSelect from "../dashboard/plant_select";
import TreePms from "../tree/tree_pms";
import useInterval from "../hooks/interval";
import DataPlants from "./data_plants";
//import FormList from "./form_list";
import FormList from "../views/quality/form_list";

import {
  responseLog,
  responseInfo,
  responseError,
} from "../utils/response_console";

const QualityView = (props) => {
  const [plant, setPlant] = useState({ id: "", name: "", active_lines: [] });
  const [plants, setPlants] = useState([]);
  const [update, setUpdate] = useState(0);
  const [showDash, setShowDash] = useState(true);
  const timeUpdate = 5 * 60 * 1000;
  const urlPlants = "/api/react/v1/dashboard/plants";
  const test = false;

  const handleChangePlant = (event) => {
    let [first] = plants.filter((item) => item.id == event.target.value);
    setPlant(first);
  };

  const changePlant = (id) => {
    let [first] = plants.filter((item) => item.id == id);
    setPlant(first);
  };

  const handleChangeView = (event) => {
    setShowDash((current) => !current);
  };

  useInterval(() => {
    setUpdate((prevUpdate) => prevUpdate + 1);
    console.log("Update  " + update);
  }, timeUpdate);

  useEffect(() => {
    if (!test) {
      axios
        .get(urlPlants)
        .then((response) => {
          responseInfo(response, true);
          setPlants(response.data);
          let [first] = response.data;
          setPlant(first);
        })
        .catch((error) => {
          responseError(error);
        });
    } else {
      console.log("-> QualityView Test ON !");
      setPlants(DataPlants);
      let [first] = DataPlants;
      setPlant(first);
    }
  }, [update]);

  return (
    <Container maxWidth="false">
      <Grid container spacing={2}>
        <Grid xs={12} sx={{ display: { xs: "block", sm: "none" } }}>
          <PlantSelect
            label="Planta"
            list={plants}
            selected={plant.id}
            handleChange={handleChangePlant}
          />
        </Grid>
        <Grid sm={4} md={2} sx={{ display: { xs: "none", sm: "block" } }}>
          <TreePms plants={plants} changePlant={changePlant} />
        </Grid>

        <Grid container spacing={2} sm={8} md={10}>
          <Grid sm={8} md={10}>
            {showDash ? (
              <Button
                size="small"
                variant="outlined"
                startIcon={<ListIcon />}
                color="primary"
                onClick={handleChangeView}
              >
                Listado Formularios
              </Button>
            ) : (
              <Button
                size="small"
                variant="outlined"
                startIcon={<AddIcon />}
                color="primary"
                onClick={handleChangeView}
              >
                Creación Formularios
              </Button>
            )}
          </Grid>
          {showDash ? (
            plant.active_lines.map((line) => (
              <Grid key={line.id} xs={12} md={3}>
                <QualityLineItem line={line} update={update} test={test} />
              </Grid>
            ))
          ) : (
            <Grid xs={12} sm={12} md={12}>
              <FormList />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default QualityView;
