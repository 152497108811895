import * as React from 'react';
import {
  Box, Stepper, Step, StepLabel, StepContent, Button, Paper, Typography,
  Tooltip, Tab, IconButton
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { useState } from 'react';
import InformacionOp from './InformacionOp';
import SistemaTrasiego from './SistemaTrasiego';
import { Etiquetas } from './Etiquetas';
import { Llenado } from './Llenado';
import { Personal } from './Personal';
import InspeccionMaquinas from './InspeccionMaquinas';
import AnalisisFQ from './AnalisisFQ';
import Observaciones from './Observaciones';
import Firmas from './Firmas';
const steps = [
  'INFORMACIÓN DE OPERACIONES',
  'VERIFICACIÓN DEL SISTEMA DE TRASIEGO',
  'INSPECCIÓN DE MÁQUINAS PARA EL ARRANQUE',
  'ANÁLISIS FISCO-QUIMICO PARA EL ARRANQUE',
  'INFORMACIÓN DE SUMINISTRO',
  'CALCULO DEL NIVEL DE LLENADO',
  'PERSONAL DE LÍNEA',
  'OBSERVACIONES',
  'FIRMAS'
]
export function Form() {
  const [totalTabs, setTabs] = useState(1)
  const [value, setValue] = useState(1)
  const [tabsInfOperaciones, setTabsInfOperaciones] = useState([
    <TabPanel value={"1"}>
      <InformacionOp />
    </TabPanel>
  ])
  const [tabsSistemaTrasiego, setTabsSistemaTrasiego] = useState([
    <TabPanel value={"1"}>
      <SistemaTrasiego />
    </TabPanel>
  ])
  const [tabsInspeccionMaquinas, setTabsInspeccionMaquinas] = useState([
    <TabPanel value={"1"}>
      <InspeccionMaquinas />
    </TabPanel>
  ])
  const [tabsAnalisisFQ, setTabsAnalisisFQ] = useState([
    <TabPanel value={"1"}>
      <AnalisisFQ />
    </TabPanel>
  ])
  const [tabsInfoSuministro, setTabsInfoSuministro] = useState([
    <TabPanel value={"1"}>
      <Etiquetas />
    </TabPanel>
  ])
  const [tabsLlenado, setTabsLlenado] = useState([
    <TabPanel value={"1"}>
      <Llenado />
    </TabPanel>
  ])
  const [tabsPersonal, setTabsPersonal] = useState([
    <TabPanel value={"1"}>
      <Personal />
    </TabPanel>
  ])
  const [activeStep, setActiveStep] = React.useState(0);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleReset = () => {
    setActiveStep(0);
  };
  const handleClickedStep = (index) => {
    setActiveStep(index);
  };
  function stepLabel(steps, index) {
    return (
      <StepLabel onClick={() => handleClickedStep(index)}>
        <Typography>{steps[index]}</Typography>
      </StepLabel>
    )
  }
  const stepButtons = (index) => {
    return (
      <Box display='flex' justifyContent='flex-end' sx={{ mb: 2 }}>
        <div>
          <Button
            disabled={index === 0}
            onClick={handleBack}
            sx={{ mt: 1, mr: 1 }}
          >
            Volver
          </Button>
          {index === steps.length - 1 ?
            <a href='../quality/pdf_viewer'>
              <Button
                variant="contained"
                sx={{ mt: 1 }}
              >
                {index === steps.length - 1 ? 'Enviar' : 'Siguiente'}
              </Button>
            </a>
            :
            <Button
              variant="contained"
              /*onClick={index === steps.length - 1 ? navigateToPreview : handleNext}*/
              onClick={handleNext}
              sx={{ mt: 1 }}
            >
              {index === steps.length - 1 ? 'Enviar' : 'Siguiente'}
            </Button>
          }
        </div>
      </Box>
    )
  }
  /* const [time, setTime] = React.useState(dayjs());
  const handleTime = (newTime) => {
    setValue(newTime);
  }; */
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  function renderTabs() {
    const tabs = [];
    for (let i = 1; i < totalTabs + 1; i++) {
      let tab_name = "Inspección " + i.toString();
      tabs.push(<Tab label={tab_name} value={i.toString()} />);
    }
    return (
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: 'background.paper',
          maxWidth: { xs: 320, sm: 480, md: 600, lg: 800, xl: 800 },
        }}
      >
        <TabList
          value={value}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
          {tabs}
        </TabList>
      </Box>
    )
  };
  const addInspection = () => {
    if (totalTabs + 1 <= 5) {
      setTabs(totalTabs + 1);
      setTabsInfOperaciones([
        ...tabsInfOperaciones,
        <TabPanel value={(totalTabs + 1).toString()}>
          <InformacionOp />
        </TabPanel>
      ]);
      setTabsSistemaTrasiego([
        ...tabsSistemaTrasiego,
        <TabPanel value={(totalTabs + 1).toString()}>
          <SistemaTrasiego />
        </TabPanel>
      ]);
      setTabsInspeccionMaquinas([
        ...tabsInspeccionMaquinas,
        <TabPanel value={(totalTabs + 1).toString()}>
          <InspeccionMaquinas />
        </TabPanel>
      ]);
      setTabsAnalisisFQ([
        ...tabsAnalisisFQ,
        <TabPanel value={(totalTabs + 1).toString()}>
          <AnalisisFQ />
        </TabPanel>
      ]);
      setTabsInfoSuministro([
        ...tabsInfoSuministro,
        <TabPanel value={(totalTabs + 1).toString()}>
          <Etiquetas />
        </TabPanel>
      ]);
      setTabsLlenado([
        ...tabsLlenado,
        <TabPanel value={(totalTabs + 1).toString()}>
          <Llenado />
        </TabPanel>
      ]);
      setTabsPersonal([
        ...tabsPersonal,
        <TabPanel value={(totalTabs + 1).toString()}>
          <Personal />
        </TabPanel>
      ]);
    }
  };
  const deleteInspection = () => {
    const opInfo = [...tabsInfOperaciones];
    if (totalTabs - 1 >= 1) {
      setTabs(totalTabs - 1);
      opInfo.pop();
      setTabsInfOperaciones(opInfo);
      setValue(totalTabs - 1);
    }
  };
  return (
    <div>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Paper elevation={3} sx={{ mb: 3, mt: 3 }}>
          <Typography variant='h5'>Formulario de Inspección de Línea</Typography>
          <Box sx={{ display: 'grid', gridTemplateColumns: ['repeat(1, 1fr)', 'repeat(3, 1fr)'], ml: 5, mr: 5 }}>
            <Typography variant='body1'>ASC-F-054</Typography>
            <Typography variant='body1'>REV. 7</Typography>
            <Typography variant='body1'>07/07/2021</Typography>
          </Box>
        </Paper>
      </Box>
      <Box sx={{
        mt: [1, 1],
        mr: [2, 10],
        ml: [2, 10],
        mb: 5
      }}>
        <TabContext value={value.toString()}>
          <Box zIndex={4} position={'sticky'} bgcolor={'white'} top={0} sx={{
            display: 'grid',
            gridTemplateColumns: ['repeat(3, 68% 8% 5%)', 'auto 4% 4%'],
            gap: 3,
            mb: [1, 3]
          }}>
            {renderTabs()}
            <Tooltip title="Agregar Inspección">
              <IconButton onClick={addInspection}>
                <AddCircleRoundedIcon color='success' />
              </IconButton>
            </Tooltip>
            <Tooltip title="Eliminar Inspección">
              <IconButton onClick={deleteInspection}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <Box>
            <Stepper activeStep={activeStep} orientation="vertical">
              <Step sx={{
                '& .MuiStepLabel-root .Mui-completed': {
                  color: 'green', // circle color (COMPLETED)
                },
                '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
                {
                  color: 'grey.500', // Just text label (COMPLETED)
                },
                '& .MuiStepLabel-root .Mui-active': {
                  color: 'primary', // circle color (ACTIVE)
                },
                '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                  fill: 'white', // circle's number (ACTIVE)
                },
              }}>
                {stepLabel(steps, 0)}
                <StepContent children>
                  {tabsInfOperaciones}
                  {stepButtons(0)}
                </StepContent>
              </Step>
              <Step sx={{
                '& .MuiStepLabel-root .Mui-completed': {
                  color: 'green', // circle color (COMPLETED)
                },
                '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
                {
                  color: 'grey.500', // Just text label (COMPLETED)
                },
                '& .MuiStepLabel-root .Mui-active': {
                  color: 'primary', // circle color (ACTIVE)
                },
                '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                  fill: 'white', // circle's number (ACTIVE)
                },
              }}>
                {stepLabel(steps, 1)}
                <StepContent>
                  {tabsSistemaTrasiego}
                  {stepButtons(1)}
                </StepContent>
              </Step>
              <Step sx={{
                '& .MuiStepLabel-root .Mui-completed': {
                  color: 'green', // circle color (COMPLETED)
                },
                '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
                {
                  color: 'grey.500', // Just text label (COMPLETED)
                },
                '& .MuiStepLabel-root .Mui-active': {
                  color: 'primary', // circle color (ACTIVE)
                },
                '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                  fill: 'white', // circle's number (ACTIVE)
                },
              }}>
                {stepLabel(steps, 2)}
                <StepContent>
                  {tabsInspeccionMaquinas}
                  {stepButtons(2)}
                </StepContent>
              </Step>
              <Step sx={{
                '& .MuiStepLabel-root .Mui-completed': {
                  color: 'green', // circle color (COMPLETED)
                },
                '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
                {
                  color: 'grey.500', // Just text label (COMPLETED)
                },
                '& .MuiStepLabel-root .Mui-active': {
                  color: 'primary', // circle color (ACTIVE)
                },
                '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                  fill: 'white', // circle's number (ACTIVE)
                },
              }}>
                {stepLabel(steps, 3)}
                <StepContent>
                  {tabsAnalisisFQ}
                  {stepButtons(3)}
                </StepContent>
              </Step>
              <Step sx={{
                '& .MuiStepLabel-root .Mui-completed': {
                  color: 'green', // circle color (COMPLETED)
                },
                '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
                {
                  color: 'grey.500', // Just text label (COMPLETED)
                },
                '& .MuiStepLabel-root .Mui-active': {
                  color: 'primary', // circle color (ACTIVE)
                },
                '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                  fill: 'white', // circle's number (ACTIVE)
                },
              }}>
                {stepLabel(steps, 4)}
                <StepContent>
                  {tabsInfoSuministro}
                  {stepButtons(4)}
                </StepContent>
              </Step>
              <Step sx={{
                '& .MuiStepLabel-root .Mui-completed': {
                  color: 'green', // circle color (COMPLETED)
                },
                '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
                {
                  color: 'grey.500', // Just text label (COMPLETED)
                },
                '& .MuiStepLabel-root .Mui-active': {
                  color: 'primary', // circle color (ACTIVE)
                },
                '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                  fill: 'white', // circle's number (ACTIVE)
                },
                '& .MuiStepLabel .Mui-incompleted': {
                  color: 'red'
                }
              }}>
                {stepLabel(steps, 5)}
                <StepContent>
                  {tabsLlenado}
                  {stepButtons(5)}
                </StepContent>
              </Step >
              <Step sx={{
                '& .MuiStepLabel-root .Mui-completed': {
                  color: 'green', // circle color (COMPLETED)
                },
                '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
                {
                  color: 'grey.500', // Just text label (COMPLETED)
                },
                '& .MuiStepLabel-root .Mui-active': {
                  color: 'primary', // circle color (ACTIVE)
                },
                '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                  fill: 'white', // circle's number (ACTIVE)
                },
                '& .MuiStepLabel .Mui-incompleted': {
                  color: 'red'
                }
              }}>
                {stepLabel(steps, 6)}
                <StepContent>
                  {tabsPersonal}
                  {stepButtons(6)}
                </StepContent>
              </Step>
              <Step sx={{
                '& .MuiStepLabel-root .Mui-completed': {
                  color: 'green', // circle color (COMPLETED)
                },
                '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
                {
                  color: 'grey.500', // Just text label (COMPLETED)
                },
                '& .MuiStepLabel-root .Mui-active': {
                  color: 'primary', // circle color (ACTIVE)
                },
                '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                  fill: 'white', // circle's number (ACTIVE)
                },
                '& .MuiStepLabel .Mui-incompleted': {
                  color: 'red'
                }
              }}>
                {stepLabel(steps, 7)}
                <StepContent>
                  <Observaciones />
                  {stepButtons(7)}
                </StepContent>
              </Step>
              <Step sx={{
                '& .MuiStepLabel-root .Mui-completed': {
                  color: 'green', // circle color (COMPLETED)
                },
                '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
                {
                  color: 'grey.500', // Just text label (COMPLETED)
                },
                '& .MuiStepLabel-root .Mui-active': {
                  color: 'primary', // circle color (ACTIVE)
                },
                '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                  fill: 'white', // circle's number (ACTIVE)
                },
                '& .MuiStepLabel .Mui-incompleted': {
                  color: 'red'
                }
              }}>
                {stepLabel(steps, 8)}
                <StepContent children>
                  <Firmas />
                  {stepButtons(8)}
                </StepContent>
              </Step>
            </Stepper>
            {activeStep === steps.length && (
              <Paper square elevation={0} sx={{ p: 3 }}>
                <Typography>Terminado</Typography>
                <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                  Reset
                </Button>
              </Paper>
            )}
          </Box>
        </TabContext>
      </Box>
    </div>
  );
}