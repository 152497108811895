import * as React from 'react';
import { Box, Button, Paper, Tab, Tooltip, Typography} from '@mui/material';
//import {useNavigate} from 'react-router-dom';
import Create from "./Create";

const responsive = {
    xs: 'repeat(1, 1fr)',
    sm: 'repeat(2, 1fr)',
    md: 'repeat(2, 1fr)',
    lg: 'repeat(2, 1fr)',
    xl: 'repeat(3, 1fr)',
};
  
export default function pdfViewer(){

    const PDF = Create()

    console.log(PDF)
    return(
        <Box sx={{
            mt: [10, 12],
            mr: [2, 10],
            ml: [2, 10],
            mb: 5
            }}>
                
            {/* HEADER */}
            <Paper elevation={3} sx={{mb: 3, mt: 3}}>
                <Typography variant='h5'>Pre-visualización de PDF</Typography>
            </Paper>
            <Paper elevation={3} sx={{mb: 3, mt: 3}}>
              <object data={PDF} 
                      width="90%"
                      height="800"
                      style={{margin: '1%'}}> 
              </object>
              <Box sx={{ 
                display: 'grid', 
                gridTemplateColumns: responsive, 
                gridTemplateAreas: {
                    xs: `"buttons"`,
                    sm: `" .  buttons"`,
                    md: ' .  buttons',
                    lg: '. . buttons',
                    xl: `". . buttons"`,
                },
                gap: 3
                }}>
                  <Box align='right' sx={{ display: 'grid', margin: '2%', gridTemplateColumns: 'repeat(2, 1fr)', gridArea: 'buttons'}}>
                    <a href="../quality/linef">
                        <Button
                          sx={{ mt: 1 }}
                        >
                            Corregir
                        </Button>
                    </a>
                    <a href="../quality/develop">
                        <Button
                            variant="contained"
                            sx={{ mt: 1}}
                        >
                            Confirmar
                        </Button>
                    </a>
                  </Box>
              </Box>
            </Paper>

        </Box>
    )
}