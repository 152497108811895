/**
 * Component SelectField
 * @author: Luis Avello
 * @version 1.0.0
 * @updated 2023-08-11
 */
import React, { useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const SelectField = (props) => {
  const { name, value, handleChange, data, all = "Todos" } = props;

  return (
    <FormControl fullWidth size="small">
      <InputLabel id={`pms-select-label-${name}`}>{name}</InputLabel>
      <Select
        labelId={`pms-select-label-${name}`}
        id={`pms-select-${name}`}
        value={value}
        onChange={(newValue) => handleChange(newValue)}
        label={name}
      >
        <MenuItem key={0} value={0}>
          {all}
        </MenuItem>
        {data.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectField;
