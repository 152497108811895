/**
 * Component LoginInspector
 * @author: Luis Avello
 * @version 1.0.0
 * @updated 2023-05-16
 */
import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import { responseInfo, responseError } from "../utils/response_console";

const LoginInspector = (props) => {
  const { test = true } = props;

  const [listInspector, setListInspector] = useState([]);
  const [selected, setSelected] = useState(0);
  const urlInspectors = "/api/react/v1/quality/inspectors";

  useEffect(() => {
    axios
      .get(urlInspectors, {
        params: {},
      })
      .then((response) => {
        responseInfo(response, true);
        setListInspector(response.data);
      })
      .catch((error) => {
        responseError(error);
      });
  }, []);

  const handleClickLogin = (event) => {};

  const handleChange = (event) => {
    console.log("onChange Select: " + event.target.value);
    setSelected(event.target.value);
  };

  return (
    <Container maxWidth="false">
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Card sx={{ boxShadow: 2 }}>
          <CardContent>
            <Typography variant="h5" sx={{ mb: 1 }}>
              Ingresar Inspector
            </Typography>
            <Stack direction="column" spacing={1}>
              <FormControl fullWidth size="small">
                <InputLabel id="inspector-select-label">Inspector</InputLabel>
                <Select
                  labelId="inspector-select-label"
                  id="inspector-select"
                  value={selected}
                  label="Inspector"
                  onChange={(event) => handleChange(event)}
                >
                  {listInspector.map((inspector) => (
                    <MenuItem key={inspector.id} value={inspector.id}>
                      {inspector.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                id="outlined-password-input"
                label="PIN"
                type="password"
                autoComplete="current-password"
              />
            </Stack>
          </CardContent>
          <CardActions>
            <Button variant="contained" onClick={handleClickLogin} size="small">
              Ingresar
            </Button>
          </CardActions>
        </Card>
      </Box>
    </Container>
  );
};

export default LoginInspector;
