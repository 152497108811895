import * as React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

function getIndexTab(list, key, value) {
  for (var i = 0; i < list.length; i++) {
    var item = list[i];
    if (item[key] == value)
      return i;
  }
  return null;
}

export default function FullWidthTabs(props) {
  const theme = useTheme();
  const value = getIndexTab(props.headerTabs, "value", props.currentValue);

  const handleChange = (event, newValue) => {
    props.handleChange(props.headerTabs[newValue].value);
  };

  const handleChangeIndex = (index) => {
    props.handleChange(props.headerTabs[index].value);
  };

  return (
    <Box sx={{ bgcolor: 'background.paper', width: "100%" }}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          {
            props.headerTabs.map((dict, i) => {
              return <Tab label={dict.label} {...a11yProps(i)} key={i}/>
            })
          }
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        {
            props.tabPanels.map((elements, i) => {
              return (
                <TabPanel value={value} index={i} dir={theme.direction} key={i}>
                  {elements}
                </TabPanel>
              )
            })
        }
      </SwipeableViews>
    </Box>
  );
}