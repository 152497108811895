//import { Nav } from './Nav.tsx';
import React from 'react'
import { Box, Paper } from "@mui/material"
import Typography from '@mui/material/Typography';
//import { Form } from './temp';
import { Form } from './Form.jsx';
//import Inspecciones from './Inspecciones';
//import { TabsInspecciones } from './TabsInspecciones';

function App() {
  console.log('testlineform')
  return (
    <div>
      <Box sx={{display:'flex',justifyContent:'center'}}>
        <Form/>
      </Box>
      
    </div>
  )
}

export default App;
