// Component BaseAwaitText
// @author: Vicente Illanes
// @version: 21.12.2022

// import React, { useState } from 'react';
import React from "react";
import TextField from "@mui/material/TextField";

class BaseAwaitText extends React.Component {
  state = {
    isInputFunctionRunning: false,
    timeout: this.props.timeout || 2000
  };

  /**
   * Construye una función que ejecuta la funcion 'fn' despues de 'delay' milisegundos
   * @param {Function} fn
   * @param {Number} delay
   * @return {Function}
   */
  debounce = (fn, delay) => {
    let timeoutID = null;
    return function () {
      clearTimeout(timeoutID);
      var args = arguments;
      var that = this;
      timeoutID = setTimeout(() => {
        fn.apply(that, args);
      }, delay);
    };
  };

  /**
   * Actualizamos el valor despues de delay milisegundos
   * @param {Object} event 
   */
  waitForText = (event) => {
    this.debounce(() => {
      if (this.state.isInputFunctionRunning) {
        return;
      }
      this.setState({isInputFunctionRunning: true});
      this.props.onChangeValue(event.target.value);
      this.setState({isInputFunctionRunning: false});
    }, this.state.timeout)();
  };


  render() {
    return (
      <TextField
        required={this.props.required || false}
        id={this.props.id || "text"}
        label={this.props.label || ""}
        defaultValue={this.props.value || ""}
        onChange={(e) => this.waitForText(e)}
      />
    );
  }
}

export default BaseAwaitText;
