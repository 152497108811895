import * as React from "react";
import { Box, Button, Paper, Tab, Tooltip, Typography } from "@mui/material";
//import {useNavigate} from 'react-router-dom';
import ConvertToPdf from "./ConvertToPdf";

const responsive = {
  xs: "repeat(1, 1fr)",
  sm: "repeat(2, 1fr)",
  md: "repeat(2, 1fr)",
  lg: "repeat(2, 1fr)",
  xl: "repeat(3, 1fr)",
};

function PDFpreview() {
  const PDF = ConvertToPdf();

  console.log(PDF);

  //const navigate = useNavigate();

  /*

    const navigateToHome = () => {
        // 👇️ navigate to /contacts
        navigate('/');
    };

    const navigateToForm = () => {
      // 👇️ navigate to /contacts
      navigate('/finished-product');

    
    };

    */

  return (
    <Box
      sx={{
        mt: [10, 12],
        mr: [2, 10],
        ml: [2, 10],
        mb: 5,
      }}
    >
      {/* HEADER */}
      <Paper elevation={1} sx={{ p: 1, mb: 3, mt: 3 }}>
        <Typography variant="h5">
          Previsualización PDF Producto Terminado
        </Typography>
      </Paper>
      <Paper elevation={2} sx={{ mb: 3, mt: 3 }}>
        <object
          data={PDF}
          width="90%"
          height="800"
          style={{ margin: "1%" }}
        ></object>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: responsive,
            gridTemplateAreas: {
              xs: `"buttons"`,
              sm: `" .  buttons"`,
              md: " .  buttons",
              lg: ". . buttons",
              xl: `". . buttons"`,
            },
            gap: 3,
          }}
        >
          <Box
            align="right"
            sx={{
              display: "grid",
              margin: "2%",
              gridTemplateColumns: "repeat(2, 1fr)",
              gridArea: "buttons",
            }}
          >
            <a href="#">
              <Button sx={{ mt: 1 }}>Volver</Button>
            </a>
            <a href="#">
              <Button variant="contained" sx={{ mt: 1 }}>
                Firmar
              </Button>
            </a>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}

export default PDFpreview;
