import './App.css';
import Router from './Router';

function App() {
  return (
    <body className='App'>
      <Router></Router>
    </body>  
  );
}

export default App;
