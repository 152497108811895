/**
 * Component PdfLine
 * @author: Luis Avello
 * @version 1.3.1
 * @updated 2023-07-18
 */
import jsPDF, { AcroFormCheckBox, AcroFormTextField } from "jspdf";
import logo from "../imgs/logo_varela.png";
import "jspdf-autotable";

function addCheckBox(field, pdf, fontSize, checked) {
  field.readOnly = true;
  field.fontSize = fontSize;
  let appearanceState = "Off";
  if (checked) appearanceState = "On";
  field.appearanceState = appearanceState;
  pdf.addField(field);
}

export default function PdfLine(data) {
  const pdf = new jsPDF({ unit: "px", pageSize: "letter" });
  var width = pdf.internal.pageSize.getWidth();
  console.log("Ejecutando PdfLine");
  pdf.setFont("helvetica", "bold");
  pdf.setFontSize(11);

  let fontsize_table = 7;

  //var height = pdf.internal.pageSize.getHeight()
  pdf.setLineHeightFactor(1.8);

  pdf.text(["INSPECCIÓN DE LÍNEA"], width / 2, 20, { align: "center" });
  pdf.text("Varela Hermanos, S.A.", width / 2, 30, { align: "center" });
  pdf.addImage(logo, "JPEG", 20, 10, 30, 25);
  pdf.line(20, 35, width - 20, 35);
  pdf.setFontSize(8);

  pdf.text(
    [data.header.form_code, data.header.revision, data.header.updated],
    430,
    12,
    {
      align: "right",
      lineHeightFactor: 1.25,
    }
  );

  const num_inspections = data.body.OPERATIONS_INFO.PRODUCT_NAME.VALUES.length;
  //informacion de operaciones

  pdf.setFont("helvetica", "bold");
  pdf.text("INFORMACIÓN DE OPERACIONES", width / 4, 50, { align: "center" });
  pdf.setFont("helvetica", "normal");
  pdf.rect(20, 40, width / 2 - 40, 12);
  pdf.text(
    [
      "Producto:",
      "Fecha de producción:",
      "Presentación:",
      "# de Línea:",
      "Hora de arranque:",
    ],
    20,
    60
  );

  pdf.text(
    [
      data.body.OPERATIONS_INFO.PRODUCT_NAME.VALUES[0],
      data.body.OPERATIONS_INFO.DATE_ORDER_PRODUCTION.VALUES[0],
      data.body.OPERATIONS_INFO.PRODUCT_PRESENTATION.VALUES[0],
      data.body.OPERATIONS_INFO.LINE.VALUES[0],
      data.body.OPERATIONS_INFO.START_TIME.VALUES[0],
    ],
    100,
    60
  );

  //verificacion del sistema de trasiego
  pdf.setFont("helvetica", "bold");
  pdf.text("VERIFICACIÓN DEL SISTEMA DE TRASIEGO", (width * 3) / 4, 50, {
    align: "center",
  });
  pdf.setFont("helvetica", "normal");
  pdf.rect(width / 2 + 20, 40, width / 2 - 40, 12);
  pdf.text(
    ["Línea de trasiego:", "Mangueras:", "Bombas:", "Filtros:"],
    width / 2 + 20,
    60,
    { baseline: "top" }
  );

  pdf.text(
    [
      data.body.VERIF_SYSTEM_TRAS.LINE_TRAS.VALUES_ALT[0],
      data.body.VERIF_SYSTEM_TRAS.HOSES.VALUES_ALT[0],
      data.body.VERIF_SYSTEM_TRAS.WATER_PUMPS.VALUES_ALT[0],
      data.body.VERIF_SYSTEM_TRAS.FILTERS.VALUES_ALT[0],
    ],
    width / 2 + 110,
    60
  );

  //inspeccion de maquinas para el arranque
  pdf.setFont("helvetica", "bold");
  pdf.text("INSPECCIÓN DE MÁQUINAS PARA EL ARRANQUE", width / 4, 130, {
    align: "center",
  });
  pdf.setFont("helvetica", "normal");
  pdf.rect(20, 120, width / 2 - 40, 12);
  pdf.text(
    [
      "Nivel de llenado:",
      "Tapado:",
      "Etiquetado:",
      "Codificado de envase:",
      "Codificado cajas:",
    ],
    20,
    140
  );

  pdf.text(
    [
      data.body.INSPECTION_MACHINES.FILL_LEVEL.VALUES_ALT[0],
      data.body.INSPECTION_MACHINES.COVERED.VALUES_ALT[0],
      data.body.INSPECTION_MACHINES.LABELLED.VALUES_ALT[0],
      data.body.INSPECTION_MACHINES.PACKAGING_CODING.VALUES_ALT[0],
      data.body.INSPECTION_MACHINES.BOX_ENCODING.VALUES_ALT[0],
    ],
    100,
    140
  );

  //analisis fisico-quimico
  pdf.setFont("helvetica", "bold");
  pdf.text("ANÁLISIS FÍSICO-QUÍMICO PARA EL ARRANQUE", (width * 3) / 4, 130, {
    align: "center",
  });
  pdf.setFont("helvetica", "normal");
  pdf.rect(width / 2 + 20, 120, width / 2 - 40, 12);
  pdf.text(
    [
      "Grado Alcohólico Aparente:",
      "Dentro del rango",
      "pH",
      "Prueba Organoléptica:",
    ],
    width / 2 + 20,
    140
  );

  pdf.text(
    [
      data.body.PHYSICAL_CHEMICAL_ANALYSIS.APPARENT_ALCOHOLIC_DEGREE.VALUES[0],
      data.body.PHYSICAL_CHEMICAL_ANALYSIS.INSIDE_THE_RANGE.VALUES_ALT[0],
      data.body.PHYSICAL_CHEMICAL_ANALYSIS.PH.VALUES[0],
      data.body.PHYSICAL_CHEMICAL_ANALYSIS.ORGANOLEPTIC_TEST.VALUES_ALT[0],
    ],
    width / 2 + 110,
    140
  );

  pdf.setFont("helvetica", "bold");
  pdf.text(["INFORMACIÓN DE SUMINISTRO"], width / 2, 200, { align: "center" });
  pdf.setFont("helvetica", "normal");
  // inf de suministro
  pdf.rect(20, 190, width - 40, 12);

  pdf.setLineHeightFactor(1.9);

  /***** etiquetas *****/
  pdf.text(
    [
      "HORA:",
      "Frente:",
      "Recetario:",
      "Cuello:",
      "Hombro:",
      "Ovalo:",
      "Strip:",
    ],
    30,
    230
  );

  const label_front = [];
  for (let i = 0; i < num_inspections; i++) {
    label_front.push(
      data.body.SUPPLY_INFO.SUPPLY_INFO_LABELS.SUPPLY_INFO_LABELS_FRONT.SUPPLIER
        .VALUES[i] +
        " " +
        data.body.SUPPLY_INFO.SUPPLY_INFO_LABELS.SUPPLY_INFO_LABELS_FRONT.OT_OC
          .VALUES[i] +
        " " +
        data.body.SUPPLY_INFO.SUPPLY_INFO_LABELS.SUPPLY_INFO_LABELS_FRONT
          .NUM_OT_OC.VALUES[i]
    );
  }
  const label_recipebook = [];
  for (let i = 0; i < num_inspections; i++) {
    label_recipebook.push(
      data.body.SUPPLY_INFO.SUPPLY_INFO_LABELS.SUPPLY_INFO_LABELS_RECIPEBOOK
        .SUPPLIER.VALUES[i] +
        " " +
        data.body.SUPPLY_INFO.SUPPLY_INFO_LABELS.SUPPLY_INFO_LABELS_RECIPEBOOK
          .OT_OC.VALUES[i] +
        " " +
        data.body.SUPPLY_INFO.SUPPLY_INFO_LABELS.SUPPLY_INFO_LABELS_RECIPEBOOK
          .NUM_OT_OC.VALUES[i]
    );
  }
  const label_neck = [];
  for (let i = 0; i < num_inspections; i++) {
    label_neck.push(
      data.body.SUPPLY_INFO.SUPPLY_INFO_LABELS.SUPPLY_INFO_LABELS_NECK.SUPPLIER
        .VALUES[i] +
        " " +
        data.body.SUPPLY_INFO.SUPPLY_INFO_LABELS.SUPPLY_INFO_LABELS_NECK.OT_OC
          .VALUES[i] +
        " " +
        data.body.SUPPLY_INFO.SUPPLY_INFO_LABELS.SUPPLY_INFO_LABELS_NECK
          .NUM_OT_OC.VALUES[i]
    );
  }
  const label_shoulder = [];
  for (let i = 0; i < num_inspections; i++) {
    label_shoulder.push(
      data.body.SUPPLY_INFO.SUPPLY_INFO_LABELS.SUPPLY_INFO_LABELS_SHOULDER
        .SUPPLIER.VALUES[i] +
        " " +
        data.body.SUPPLY_INFO.SUPPLY_INFO_LABELS.SUPPLY_INFO_LABELS_SHOULDER
          .OT_OC.VALUES[i] +
        " " +
        data.body.SUPPLY_INFO.SUPPLY_INFO_LABELS.SUPPLY_INFO_LABELS_SHOULDER
          .NUM_OT_OC.VALUES[i]
    );
  }
  const label_oval = [];
  for (let i = 0; i < num_inspections; i++) {
    label_oval.push(
      data.body.SUPPLY_INFO.SUPPLY_INFO_LABELS.SUPPLY_INFO_LABELS_OVAL.SUPPLIER
        .VALUES[i] +
        " " +
        data.body.SUPPLY_INFO.SUPPLY_INFO_LABELS.SUPPLY_INFO_LABELS_OVAL.OT_OC
          .VALUES[i] +
        " " +
        data.body.SUPPLY_INFO.SUPPLY_INFO_LABELS.SUPPLY_INFO_LABELS_OVAL
          .NUM_OT_OC.VALUES[i]
    );
  }
  const label_strip = [];
  for (let i = 0; i < num_inspections; i++) {
    label_strip.push(
      data.body.SUPPLY_INFO.SUPPLY_INFO_LABELS.SUPPLY_INFO_LABELS_STRIP.SUPPLIER
        .VALUES[i] +
        " " +
        data.body.SUPPLY_INFO.SUPPLY_INFO_LABELS.SUPPLY_INFO_LABELS_STRIP.OT_OC
          .VALUES[i] +
        " " +
        data.body.SUPPLY_INFO.SUPPLY_INFO_LABELS.SUPPLY_INFO_LABELS_STRIP
          .NUM_OT_OC.VALUES[i]
    );
  }

  // Etiquetas
  pdf.autoTable({
    head: [
      [
        "Inspección 1",
        "Inspección 2",
        "Inspección 3",
        "Inspección 4",
        "Inspección 5",
      ],
    ],
    body: [
      data.body.SUPPLY_INFO.HOUR.VALUES,
      label_front,
      label_recipebook,
      label_neck,
      label_shoulder,
      label_oval,
      label_strip,
    ],
    startY: 207,
    margin: { left: 150, right: 20 },
    styles: { fontSize: fontsize_table, cellPadding: 2.502 },
    theme: "grid",
    headStyles: { halign: "center", fillColor: [41, 128, 186] },
  });

  var frente_Autoad = new AcroFormCheckBox();
  frente_Autoad.fieldName = "frente autoadhesivo";
  frente_Autoad.Rect = [70, 233, 8, 8];
  addCheckBox(
    frente_Autoad,
    pdf,
    11,
    data.body.SUPPLY_INFO.SUPPLY_INFO_LABELS.SUPPLY_INFO_LABELS_FRONT
      .GLUING_METHOD.VALUES[0] == "SELF_ADHESIVE"
  );

  var recetario_Autoad = new AcroFormCheckBox();
  recetario_Autoad.fieldName = "recetario autoadhesivo";
  recetario_Autoad.Rect = [70, 245, 8, 8];
  addCheckBox(
    recetario_Autoad,
    pdf,
    11,
    data.body.SUPPLY_INFO.SUPPLY_INFO_LABELS.SUPPLY_INFO_LABELS_RECIPEBOOK
      .GLUING_METHOD.VALUES[0] == "SELF_ADHESIVE"
  );

  var cuello_Autoad = new AcroFormCheckBox();
  cuello_Autoad.fieldName = "cuello autoadhesivo";
  cuello_Autoad.Rect = [70, 257, 8, 8];
  addCheckBox(
    cuello_Autoad,
    pdf,
    11,
    data.body.SUPPLY_INFO.SUPPLY_INFO_LABELS.SUPPLY_INFO_LABELS_NECK
      .GLUING_METHOD.VALUES[0] == "SELF_ADHESIVE"
  );

  var hombro_Autoad = new AcroFormCheckBox();
  hombro_Autoad.fieldName = "hombro autoadhesivo";
  hombro_Autoad.Rect = [70, 269, 8, 8];
  addCheckBox(
    hombro_Autoad,
    pdf,
    11,
    data.body.SUPPLY_INFO.SUPPLY_INFO_LABELS.SUPPLY_INFO_LABELS_SHOULDER
      .GLUING_METHOD.VALUES[0] == "SELF_ADHESIVE"
  );

  var ovalo_Autoad = new AcroFormCheckBox();
  ovalo_Autoad.fieldName = "ovalo autoadhesivo";
  ovalo_Autoad.Rect = [70, 281, 8, 8];
  addCheckBox(
    ovalo_Autoad,
    pdf,
    11,
    data.body.SUPPLY_INFO.SUPPLY_INFO_LABELS.SUPPLY_INFO_LABELS_OVAL
      .GLUING_METHOD.VALUES[0] == "SELF_ADHESIVE"
  );

  var strip_Autoad = new AcroFormCheckBox();
  strip_Autoad.fieldName = "strip autoadhesivo";
  strip_Autoad.Rect = [70, 293, 8, 8];
  addCheckBox(
    strip_Autoad,
    pdf,
    11,
    data.body.SUPPLY_INFO.SUPPLY_INFO_LABELS.SUPPLY_INFO_LABELS_STRIP
      .GLUING_METHOD.VALUES[0] == "SELF_ADHESIVE"
  );

  var frente_ColaFria = new AcroFormCheckBox();
  frente_ColaFria.fieldName = "frente ColaFria";
  frente_ColaFria.Rect = [100, 233, 8, 8];
  addCheckBox(
    frente_ColaFria,
    pdf,
    11,
    data.body.SUPPLY_INFO.SUPPLY_INFO_LABELS.SUPPLY_INFO_LABELS_FRONT
      .GLUING_METHOD.VALUES[0] == "COLD_GLUE"
  );

  var recetario_ColaFria = new AcroFormCheckBox();
  recetario_ColaFria.fieldName = "recetario ColaFria";
  recetario_ColaFria.Rect = [100, 245, 8, 8];
  addCheckBox(
    recetario_ColaFria,
    pdf,
    11,
    data.body.SUPPLY_INFO.SUPPLY_INFO_LABELS.SUPPLY_INFO_LABELS_RECIPEBOOK
      .GLUING_METHOD.VALUES[0] == "COLD_GLUE"
  );

  var cuello_ColaFria = new AcroFormCheckBox();
  cuello_ColaFria.fieldName = "cuello ColaFria";
  cuello_ColaFria.Rect = [100, 257, 8, 8];
  addCheckBox(
    cuello_ColaFria,
    pdf,
    11,
    data.body.SUPPLY_INFO.SUPPLY_INFO_LABELS.SUPPLY_INFO_LABELS_NECK
      .GLUING_METHOD.VALUES[0] == "COLD_GLUE"
  );

  var hombro_ColaFria = new AcroFormCheckBox();
  hombro_ColaFria.fieldName = "hombro ColaFria";
  hombro_ColaFria.Rect = [100, 269, 8, 8];
  addCheckBox(
    hombro_ColaFria,
    pdf,
    11,
    data.body.SUPPLY_INFO.SUPPLY_INFO_LABELS.SUPPLY_INFO_LABELS_SHOULDER
      .GLUING_METHOD.VALUES[0] == "COLD_GLUE"
  );

  var ovalo_ColaFria = new AcroFormCheckBox();
  ovalo_ColaFria.fieldName = "ovalo ColaFria";
  ovalo_ColaFria.Rect = [100, 281, 8, 8];
  addCheckBox(
    ovalo_ColaFria,
    pdf,
    11,
    data.body.SUPPLY_INFO.SUPPLY_INFO_LABELS.SUPPLY_INFO_LABELS_OVAL
      .GLUING_METHOD.VALUES[0] == "COLD_GLUE"
  );

  var strip_ColaFria = new AcroFormCheckBox();
  strip_ColaFria.fieldName = "strip ColaFria";
  strip_ColaFria.Rect = [100, 293, 8, 8];
  addCheckBox(
    strip_ColaFria,
    pdf,
    11,
    data.body.SUPPLY_INFO.SUPPLY_INFO_LABELS.SUPPLY_INFO_LABELS_STRIP
      .GLUING_METHOD.VALUES[0] == "COLD_GLUE"
  );

  pdf.text(
    [
      "Autoad.     Cola Fría",
      "Autoad.     Cola Fría",
      "Autoad.     Cola Fría",
      "Autoad.     Cola Fría",
      "Autoad.     Cola Fría",
      "Autoad.     Cola Fría",
    ],
    80,
    241
  );

  pdf.setLineHeightFactor(1.8);

  pdf.line(20, 306, width - 20, 306);

  /***** pegamento *****/
  pdf.text(["Tipo de Goma:", "Fecha de Prod.", "Fecha de Exp."], 30, 317);

  // Pegamento
  pdf.autoTable({
    head: [
      [
        "Inspección 1",
        "Inspección 2",
        "Inspección 3",
        "Inspección 4",
        "Inspección 5",
      ],
    ],
    body: [
      data.body.SUPPLY_INFO.SUPPLY_INFO_GLUE.RUBBER_TYPE.VALUES,
      data.body.SUPPLY_INFO.SUPPLY_INFO_GLUE.DATE_PRODUCTION.VALUES,
      data.body.SUPPLY_INFO.SUPPLY_INFO_GLUE.DATE_EXPIRATION.VALUES,
    ],
    startY: 310,
    margin: { left: 150, right: 20 },
    styles: { fontSize: fontsize_table, cellPadding: 2.2 },
    theme: "grid",
    showHead: "never",
    headStyles: { halign: "center", fillColor: [41, 128, 186] },
  });

  pdf.line(20, 347, width - 20, 347);

  /**** caja de carton corrugado ****/
  pdf.text(
    [
      "Proveedor                 GO:Goma",
      "Fecha de Prod/# de Lote   G:Grapas",
      "Sellado: G/CA/GO  CA: Cinta Adhesiva",
    ],
    30,
    358
  );

  const date_production_lot = [];
  for (let i = 0; i < num_inspections; i++) {
    date_production_lot.push(
      data.body.SUPPLY_INFO.SUPPLY_INFO_BOX.DATE_PRODUCTION.VALUES[i] +
        " " +
        data.body.SUPPLY_INFO.SUPPLY_INFO_BOX.NUM_LOT.VALUES[i]
    );
  }

  // Caja de cartón
  pdf.autoTable({
    head: [
      [
        "Inspección 1",
        "Inspección 2",
        "Inspección 3",
        "Inspección 4",
        "Inspección 5",
      ],
    ],
    body: [
      data.body.SUPPLY_INFO.SUPPLY_INFO_BOX.SUPPLIER.VALUES,
      date_production_lot,
      data.body.SUPPLY_INFO.SUPPLY_INFO_BOX.SEALED.VALUES,
    ],
    startY: 350,
    margin: { left: 150, right: 20 },
    styles: { fontSize: fontsize_table, cellPadding: 2 },
    theme: "grid",
    showHead: "never",
    headStyles: { halign: "center", fillColor: [41, 128, 186] },
  });

  pdf.line(20, 385, width - 20, 385);

  pdf.setLineHeightFactor(1.6);

  /***** tapas *****/
  pdf.text(
    [
      "Modelo:",
      "NR / OP:    NR: Irrellenable",
      "Fecha de Prod.:  OP: Rellenable",
      "Proveedor:",
    ],
    30,
    397
  );

  // Tapas Modelo
  pdf.autoTable({
    head: [
      [
        "Inspección 1",
        "Inspección 2",
        "Inspección 3",
        "Inspección 4",
        "Inspección 5",
      ],
    ],
    body: [
      data.body.SUPPLY_INFO.SUPPLY_INFO_CAPS.MODEL.VALUES,
      data.body.SUPPLY_INFO.SUPPLY_INFO_CAPS.NR_OP.VALUES,
      data.body.SUPPLY_INFO.SUPPLY_INFO_CAPS.DATE_PRODUCTION.VALUES,
      data.body.SUPPLY_INFO.SUPPLY_INFO_CAPS.SUPPLIER.VALUES,
    ],
    startY: 388,
    margin: { left: 150, right: 20 },
    styles: { fontSize: fontsize_table, cellPadding: 1.7 },
    theme: "grid",
    showHead: "never",
    headStyles: { halign: "center", fillColor: [41, 128, 186] },
  });

  pdf.line(20, 431, width - 20, 431);

  // Corchos

  pdf.text(["Modelo:", "Fecha de Prod.", "# de Lote", "Proveedor:"], 30, 442);

  pdf.autoTable({
    head: [
      [
        "Inspección 1",
        "Inspección 2",
        "Inspección 3",
        "Inspección 4",
        "Inspección 5",
      ],
    ],
    body: [
      data.body.SUPPLY_INFO.SUPPLY_INFO_CORKS.MODEL.VALUES,
      data.body.SUPPLY_INFO.SUPPLY_INFO_CORKS.DATE_PRODUCTION.VALUES,
      data.body.SUPPLY_INFO.SUPPLY_INFO_CORKS.NUM_LOT.VALUES,
      data.body.SUPPLY_INFO.SUPPLY_INFO_CORKS.SUPPLIER.VALUES,
    ],
    startY: 433,
    margin: { left: 150, right: 20 },
    styles: { fontSize: fontsize_table, cellPadding: 1.5 },
    theme: "grid",
    showHead: "never",
    headStyles: { halign: "center", fillColor: [41, 128, 186] },
  });

  pdf.line(20, 476, width - 20, 476);

  // Capsulas

  pdf.text(["Modelo:", "Fecha de Prod.", "# de Lote", "Proveedor:"], 30, 488);

  pdf.autoTable({
    head: [
      [
        "Inspección 1",
        "Inspección 2",
        "Inspección 3",
        "Inspección 4",
        "Inspección 5",
      ],
    ],
    body: [
      data.body.SUPPLY_INFO.SUPPLY_INFO_CAPSULES.MODEL.VALUES,
      data.body.SUPPLY_INFO.SUPPLY_INFO_CAPSULES.DATE_PRODUCTION.VALUES,
      data.body.SUPPLY_INFO.SUPPLY_INFO_CAPSULES.NUM_LOT.VALUES,
      data.body.SUPPLY_INFO.SUPPLY_INFO_CAPSULES.SUPPLIER.VALUES,
    ],
    startY: 478,
    margin: { left: 150, right: 20 },
    styles: { fontSize: fontsize_table, cellPadding: 1.5 },
    theme: "grid",
    showHead: "never",
    headStyles: { halign: "center", fillColor: [41, 128, 186] },
  });

  pdf.setLineHeightFactor(1.7);
  // Tapas Fecha Proveedor
  pdf.line(20, 520, width - 20, 520);

  /***** tipo de envase *****/
  pdf.text(
    [
      "VR / PET / Otros:  VR: Vidrio Reciclado",
      "Fecha de Prod. (VN)  VN: Vidrio Nuevo",
      "Proveedor:",
      "Tipo de lavado: Enjuagado (E) / Lavado (L)",
    ],
    30,
    530
  );

  // Tipo de envases
  pdf.autoTable({
    head: [
      [
        "Inspección 1",
        "Inspección 2",
        "Inspección 3",
        "Inspección 4",
        "Inspección 5",
      ],
    ],
    body: [
      data.body.SUPPLY_INFO.SUPPLY_INFO_TYPE_CONTAINER.VR_PET_OTHER.VALUES,
      data.body.SUPPLY_INFO.SUPPLY_INFO_TYPE_CONTAINER.DATE_PRODUCTION.VALUES,
      data.body.SUPPLY_INFO.SUPPLY_INFO_TYPE_CONTAINER.SUPPLIER.VALUES,
      data.body.SUPPLY_INFO.SUPPLY_INFO_TYPE_CONTAINER.WASH_TYPE.VALUES_ALT,
    ],
    startY: 522,
    margin: { left: 150, right: 20 },
    styles: { fontSize: fontsize_table, cellPadding: 1.8 },
    theme: "grid",
    showHead: "never",
    headStyles: { halign: "center", fillColor: [41, 128, 186] },
  });

  pdf.setFontSize(7);
  pdf.setLineHeightFactor(1.15);
  pdf.text("Etiquetas", 15, 270, { angle: 90 });
  pdf.text("Proveedor / OT y/o OC", 140, 270, { angle: 90, maxWidth: 30 });
  pdf.text("Pegamento", 15, 340, { angle: 90, maxWidth: 50 });
  pdf.text("Caja de Cartón Corrugado", 15, 382, { angle: 90, maxWidth: 50 });
  pdf.text("Tapas", 15, 415, { angle: 90, maxWidth: 50 });
  pdf.text("Corchos", 15, 465, { angle: 90, maxWidth: 50 });
  pdf.text("Cápsulas", 15, 510, { angle: 90, maxWidth: 50 });
  pdf.text("Tipo de envase", 15, 550, { angle: 90, maxWidth: 30 });

  pdf.setFontSize(8);
  pdf.line(20, 567, width - 20, 567);
  pdf.setLineHeightFactor(2);
  pdf.text(
    [
      "Concentración de ácido cítrico:",
      "pH antes de lavado los envases",
      "pH después de lavado los envases",
    ],
    20,
    575
  );

  // Concentracion de Acido
  pdf.autoTable({
    head: [
      [
        "Inspección 1",
        "Inspección 2",
        "Inspección 3",
        "Inspección 4",
        "Inspección 5",
      ],
    ],
    body: [
      data.body.SUPPLY_INFO.SUPPLY_INFO_ACID_CONCENTRATION
        .CITRIC_ACID_CONCENTRATION.VALUES,
      data.body.SUPPLY_INFO.SUPPLY_INFO_ACID_CONCENTRATION
        .PH_BEFORE_WASHING_CONTAINERS.VALUES,
      data.body.SUPPLY_INFO.SUPPLY_INFO_ACID_CONCENTRATION
        .PH_AFTER_WASHING_CONTAINERS.VALUES,
    ],
    startY: 568,
    margin: { left: 150, right: 20 },
    styles: { fontSize: fontsize_table, cellPadding: 1.8 },
    theme: "grid",
    showHead: "never",
    headStyles: { halign: "center", fillColor: [41, 128, 186] },
  });

  /*******************************/
  /*            pag 2            */
  /*******************************/
  pdf.addPage();
  pdf.setLineHeightFactor(2);
  pdf.setFontSize(11);
  pdf.setFont("helvetica", "bold");
  pdf.text("INSPECCIÓN DE LÍNEA", width / 2, 20, { align: "center" });
  pdf.text("Varela Hermanos, S.A.", width / 2, 30, { align: "center" });
  pdf.addImage(logo, "JPEG", 20, 10, 30, 25);
  pdf.line(20, 35, width - 20, 35);
  pdf.setFontSize(8);
  //calculo del nivel de llenado

  pdf.text("CÁLCULO DEL NIVEL DE LLENADO", width / 2, 50, { align: "center" });
  pdf.setFont("helvetica", "normal");
  pdf.rect(20, 40, width - 40, 12);
  //pdf.text('Inspección 1', (width * 2) / 8 + 50, 60, { align: 'left' });
  //pdf.text('Inspección 2', (width * 3) / 8 + 50, 60, { align: 'left' });
  //pdf.text('Inspección 3', (width * 4) / 8 + 50, 60, { align: 'left' });
  //pdf.text('Inspección 4', (width * 5) / 8 + 50, 60, { align: 'left' });
  //pdf.text('Inspección 5', (width * 6) / 8 + 50, 60, { align: 'left' });

  pdf.text(
    [
      "Tanque:",
      "Lote:",
      "Día de Preparación:",
      "Prueba Organoléptica:",
      "Horas de Reposo:",
      "Alcoholímetro:",
      "Temperatura:",
      "Grado Alcohólico:",
      "pH:",
      "Línea / T. de Licor:",
      "Destino:",
      "Balanza No de Serie.:",
      "Peso de Tapas:",
      "Peso de Envases Vacíos:",
      "Peso de Envases LLenos:",
      "Peso Neto Actual:",
      "Peso Ideal:",
      "Dif. Peso Neto",
      "Límites:",
      "Dif. Porcentual:",
    ],
    20,
    70
  );

  // Calculo de nivel de llenado
  pdf.autoTable({
    head: [
      [
        "Inspección 1",
        "Inspección 2",
        "Inspección 3",
        "Inspección 4",
        "Inspección 5",
      ],
    ],
    body: [
      data.body.FILL_LEVEL_CALC.TANK.VALUES,
      data.body.FILL_LEVEL_CALC.BATCH.VALUES,
      data.body.FILL_LEVEL_CALC.PREPARATION_DAY.VALUES,
      data.body.FILL_LEVEL_CALC.ORGANOLEPTIC_TEST.VALUES_ALT,
      data.body.FILL_LEVEL_CALC.REST_HOURS.VALUES,
      data.body.FILL_LEVEL_CALC.BREATHALYSER.VALUES,
      data.body.FILL_LEVEL_CALC.TEMPERATURE.VALUES,
      data.body.FILL_LEVEL_CALC.ALCOHOLIC_GRADE.VALUES,
      data.body.FILL_LEVEL_CALC.PH.VALUES,
      data.body.FILL_LEVEL_CALC.LINE_T_LICOR.VALUES,
      data.body.FILL_LEVEL_CALC.DESTINATION.VALUES,
      data.body.FILL_LEVEL_CALC.SCALE_SERIAL_NUMBER.VALUES,
      data.body.FILL_LEVEL_CALC.CAPS_WEIGHT.VALUES,
      data.body.FILL_LEVEL_CALC.WEIGHT_EMPTY_CONTAINERS.VALUES,
      data.body.FILL_LEVEL_CALC.WEIGHT_FULL_CONTAINERS.VALUES,
      data.body.FILL_LEVEL_CALC.CURRENT_NET_WEIGHT.VALUES,
      data.body.FILL_LEVEL_CALC.IDEAL_WEIGHT.VALUES,
      data.body.FILL_LEVEL_CALC.DIFF_NET_WEIGHT.VALUES,
      data.body.FILL_LEVEL_CALC.LIMITS.VALUES,
      data.body.FILL_LEVEL_CALC.DIFERENCIA_PORCENTUAL.VALUES,
    ],
    startY: 53,
    margin: { left: 150, right: 20 },
    styles: { fontSize: fontsize_table, cellPadding: 2.5 },
    theme: "grid",
    headStyles: { halign: "center", fillColor: [41, 128, 186] },
  });

  //personal de linea
  pdf.setFont("helvetica", "bold");
  pdf.text("PERSONAL DE LÍNEA", width / 2, 320, { align: "center" });
  pdf.setFont("helvetica", "normal");
  pdf.rect(20, 310, width - 40, 12);

  pdf.setLineHeightFactor(2);
  pdf.text(
    [
      "1) Enjuag./Lavadora",
      "2) 1ra Inspección",
      "3) Llenadora",
      "4) 2da. Inspección",
      "5)Tapadora",
      "6) Etiquetadora",
      "7) Mesa de Inspección",
      "8) Recogiendo",
      "9) Estibando",
    ],
    20,
    340
  );

  // Personal de Linea
  pdf.autoTable({
    head: [
      [
        "Inspección 1",
        "Inspección 2",
        "Inspección 3",
        "Inspección 4",
        "Inspección 5",
      ],
    ],
    body: [
      data.body.LINE_STAFF.RINSER_WASHING_MACHINE.VALUES,
      data.body.LINE_STAFF.FIRST_INSPECTION.VALUES,
      data.body.LINE_STAFF.FILLER.VALUES,
      data.body.LINE_STAFF.SECOND_INSPECTION.VALUES,
      data.body.LINE_STAFF.CAPPER.VALUES,
      data.body.LINE_STAFF.LABELER.VALUES,
      data.body.LINE_STAFF.INSPECTION_TABLE.VALUES,
      data.body.LINE_STAFF.COLLECTING.VALUES,
      data.body.LINE_STAFF.STOWING.VALUES,
    ],
    startY: 323,
    margin: { left: 150, right: 20 },
    styles: { fontSize: fontsize_table, cellPadding: 2.5 },
    theme: "grid",
    headStyles: { halign: "center", fillColor: [41, 128, 186] },
  });

  pdf.text("Observaciones:", 20, 460);
  pdf.text(data.body.OBS.OBSERVATIONS.VALUES[0], 70, 460);

  var observaciones = new AcroFormTextField();
  observaciones.fieldName = "observaciones";
  observaciones.Rect = [80, 455, width - 100, 24];
  //pdf.addField(observaciones);

  pdf.text("Inspector (a):", 20, 500);
  pdf.text("Aseguramiento de la Calidad", 65, 508);
  pdf.line(60, 502, 150, 502);

  pdf.text("Verificado:", width / 3 + 20, 500, { align: "left" });
  pdf.text("Sup. de Aseguramiento de la Calidad", width / 3 + 55, 508);
  pdf.line(width / 3 + 50, 502, width / 3 + 160, 502);

  pdf.text("Informado:", (width * 2) / 3 + 30, 500, { align: "left" });
  pdf.text("Operaciones", (width * 2) / 3 + 70, 508);
  pdf.line((width * 2) / 3 + 60, 502, (width * 2) / 3 + 120, 502);

  /* guardar formulario */
  //pdf.save('testForm.pdf')
  return pdf.output("bloburl");
}

//export default PdfLine;
