/**
 * Component Header Inspection
 * @author: Luis Avello
 * @author: Vicente Illanes // 10.05.2022
 * @version 1.1.0
 * @updated 2023-03-22
 */

import React, { Component } from "react";
//import "../../styles/menu.css";
//import "../../styles/modal.css";
//import "../../styles/inspection_form/tab.css";
//import "../../styles/lateral_menu_form.css";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

class InspectionForm extends Component {
  /**
   * Obtiene el nombre del formulario
   * @returns {String}
   */
  getNameInspectionForm = () => {
    return this.props.form.name;
  };

  render() {
    /*return (
      <div className="header_menu">
        <h4>Formulario de {this.getNameInspectionForm()}</h4>
        <div className="mdl-grid subhead">
          <div className="mdl-cell mdl-cell--3-col mdl-cell--4-col-desktop">
            <h6>{this.props.form.code_form}</h6>
          </div>
          <div className="mdl-cell mdl-cell--2-col mdl-cell--4-col-desktop">
            <h6>{this.props.form.review}</h6>
          </div>
          <div className="mdl-cell mdl-cell--3-col mdl-cell--4-col-desktop">
            <h6>{this.props.form.date}</h6>
          </div>
        </div>
      </div>
    );*/
    return (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Paper elevation={3} sx={{ mb: 3, mt: 3, p: 1 }}>
          <Typography variant="h4">
            Formulario de {this.getNameInspectionForm()}
          </Typography>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: ["repeat(1, 1fr)", "repeat(3, 1fr)"],
              ml: 5,
              mr: 5,
            }}
          >
            <Typography variant="body1">{this.props.form.code_form}</Typography>
            <Typography variant="body1">{this.props.form.review}</Typography>
            <Typography variant="body1">{this.props.form.date}</Typography>
          </Box>
        </Paper>
      </Box>
    );
  }
}

export default InspectionForm;
