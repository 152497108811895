/**
 * Component FormList Trop
 * @author: Luis Avello
 * @version 1.0.2
 * @updated 2023-08-17
 */
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import KeyIcon from "@mui/icons-material/Key";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Tooltip from "@mui/material/Tooltip";

import Box from "@mui/material/Box";
import { DataGrid, GridToolbar, esES } from "@mui/x-data-grid";


import { red } from "@mui/material/colors";
import { purple } from "@mui/material/colors";
import { indigo } from "@mui/material/colors";

import SelectField from "../../fields/select_field";
import DatePickerField from "../../fields/date_picker_field";
import FormDetail from "./form_detail_trop";

import { responseInfo, responseError } from "../../utils/response_console";
import axios from "axios";
import dayjs from "dayjs";
import "dayjs/locale/es";

import DataForms from "../../dummy/data_forms_trop.js";
import DataFormColumns from "../../dummy/data_form_columns.js";
import DataStates from "../../dummy/data_states.js";
import DataLines from "../../dummy/data_lines.js";
import DataTypes from "../../dummy/data_types.js";

const FormListTrop = (props) => {
  const { dummyMode = false } = props;
  const [dateStart, setDateStart] = React.useState(dayjs());
  const [dateEnd, setDateEnd] = React.useState(dayjs());
  const [type, setType] = React.useState(0);
  const [types, setTypes] = React.useState([]);
  const [state, setState] = React.useState(0);
  const [states, setStates] = React.useState([]);
  const [line, setLine] = React.useState(0);
  const [lines, setLines] = useState([]);
  const [forms, setForms] = React.useState([]);
  const [form, setForm] = React.useState({});
  const [showDetail, setShowDetail] = React.useState(false);
  const full_width = 12;
  const compact_width = 8;
  const [tableWidth, setTableWidth] = React.useState(full_width);
  const [order, setOrder] = React.useState({ id: 0, line_id: 0 });

  const urlForms = "/api/react/v1/quality/forms";
  const urlLines = "/api/react/v1/quality/lines";
  const urlStates = "/api/react/v1/quality/states";
  const urlTypes = "/api/react/v1/quality/types";

  const urlSingleOrder = "/api/react/v1/quality/single_order";

  const color_edit = indigo[500];
  const color_pdf = red[600];
  const color_sign = purple[700];

  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);

  const handleClickedRow = (params) => {
    console.log("Row Clicked " + params.id);
    let [row] = forms.filter((item) => item.id == params.id);
    console.log("Row Product " + row.product);
    setForm(row);
    setShowDetail(true);
    setTableWidth(compact_width);
  };

  const handleCloseDetail = () => {
    setShowDetail(false);
    setTableWidth(full_width);
  };

  const handleClickPdf = (formmain_id) => {
    console.log("Pdf " + formmain_id);
    window.location = "/quality/pdf?formmain_id=" + formmain_id;
  };

  const handleClickEdit = (formmain_id) => {
    console.log("Edit " + formmain_id);
    window.location = "/quality/form?formmain_id=" + formmain_id;
  };

  const handleClickSign = (formmain_id) => {
    console.log("Sign " + formmain_id);
  };

  const handleNewForm = () => {
    let code = "TROPIGAS";
    console.log("New Form " + code);
    window.location =
      "/quality/form?code=" +
      code +
      "&line_id=" +
      order.line_id +
      "&order_id=" +
      order.id;
  };

  useEffect(() => {
    if (dummyMode) {
      console.log("FormList >> Dummy Mode");
      setForms(DataForms);
      setLines(DataLines);
      setStates(DataStates);
      setTypes(DataTypes);
    } else {
      // Get States
      axios
        .get(urlStates, {
          params: {},
        })
        .then((response) => {
          responseInfo(response, true);
          setStates(response.data);
        })
        .catch((error) => {
          responseError(error);
        });
      // Get types
      axios
        .get(urlTypes, {
          params: {},
        })
        .then((response) => {
          responseInfo(response, true);
          setTypes(response.data);
        })
        .catch((error) => {
          responseError(error);
        });
      // Get lines
      axios
        .get(urlLines, {
          params: {},
        })
        .then((response) => {
          responseInfo(response, true);
          setLines(response.data);
        })
        .catch((error) => {
          responseError(error);
        });
      // Get single order
      axios
        .get(urlSingleOrder, {
          params: {},
        })
        .then((response) => {
          responseInfo(response, true);
          setOrder(response.data);
          setLine(response.data.line_id);
        })
        .catch((error) => {
          responseError(error);
        });
      // Get forms
      axios
        .get(urlForms, {
          params: {
            line_id: line,
            date_start: dateStart.startOf("day").format("YYYY-MM-DDTHH:mm:ss"),
            date_end: dateEnd.endOf("day").format("YYYY-MM-DDTHH:mm:ss"),
            state_id: state,
            formtype_id: type,
          },
        })
        .then((response) => {
          responseInfo(response, true);
          setForms(response.data);
        })
        .catch((error) => {
          responseError(error);
        });
    }
  }, [dateStart, dateEnd, line, state, type]);

  const columns = [
    { field: "id", headerName: "ID", width: 100 },
    {
      field: "type",
      headerName: "Tipo",
      width: 300,
      resizable: true,
    },
    {
      field: "line",
      headerName: "Linea",
      width: 200,
    },
    {
      field: "inspections",
      headerName: "No Conformidades",
      type: "number",
      width: 200,
    },
    {
      field: "date_created",
      headerName: "Fecha Creación",
      width: 200,
    },
    {
      field: "state",
      headerName: "Estado",
      width: 200,
    },
  ];

  return (
    <Container maxWidth="true" sx={{ pt: 2 }}>
      <Grid container spacing={1}>
        <Grid sm={2} md={2}>
          <DatePickerField
            name="Fecha Inicio"
            date={dateStart}
            handleChange={(newDatetime) => setDateStart(newDatetime)}
            disableButtons={true}
          />
        </Grid>
        <Grid sm={2} md={2}>
          <DatePickerField
            name="Fecha Término"
            date={dateEnd}
            handleChange={(newDatetime) => setDateEnd(newDatetime)}
            disableButtons={true}
          />
        </Grid>
        <Grid sm={2} md={2}>
          <SelectField
            name="Tipo Formulario"
            value={type}
            data={types}
            handleChange={(event) => setType(event.target.value)}
          />
        </Grid>

        <Grid sm={2} md={2}>
          <SelectField
            name="Estado"
            value={state}
            data={states}
            handleChange={(event) => setState(event.target.value)}
          />
        </Grid>
        <Grid sm={2} md={2}>
          <Button
            size="normal"
            variant="contained"
            color="primary"
            onClick={handleNewForm}
          >
            Nuevo
          </Button>
        </Grid>

        <Grid sm={tableWidth} md={tableWidth} xs={12}>
          <DataGrid
            rows={forms}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5]}
            slots={{ toolbar: GridToolbar }}
            onRowClick={handleClickedRow}
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            disableRowSelectionOnClick
          />
        </Grid>
        {showDetail ? (
          <Grid sm={4} md={4} xs={12}>
            <FormDetail form={form} handleClose={handleCloseDetail} />
          </Grid>
        ) : null}
      </Grid>
    </Container>
  );
};

export default FormListTrop;
