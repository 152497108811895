import React from 'react'
import { Grid, FormControl, Radio, RadioGroup, FormControlLabel, FormLabel } from '@mui/material';
import info from './temp.json'

export default function InspeccionMaquinas() {

    const data = [{ field: info[9].field_name, val: info[9].value },
    { field: info[10].field_name, val: info[10].value },
    { field: info[11].field_name, val: info[11].value },
    { field: info[12].field_name, val: info[12].value },
    { field: info[13].field_name, val: info[13].value }]

    return (
        <Grid container spacing={4}>
            {data.map((item) => (
                <Grid item xs={12} xl={3} sm={6} md={4}>
                    <FormControl>
                        <FormLabel required>{item.field}</FormLabel>
                        <RadioGroup
                            row
                            value={item.val}
                        >
                            <FormControlLabel value="ok" control={<Radio />} label="OK" />
                            <FormControlLabel value="no ok" control={<Radio />} label="No OK" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            ))}
        </Grid>
    )
}
