import React from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import { PDFViewer } from "@react-pdf/renderer";
import HeaderPDF from './header_pdf.js';


// Create styles
const styles = StyleSheet.create({
  document: {
    width: 500,
  },
  page: {
    backgroundColor: '#fff',
    fontFamily: 'Helvetica',
    fontSize: 11,
    paddingTop: 30,
    paddingLeft: 50,
    paddingRight: 50,
    lineHeight: 1.5,
    flexDirection: 'column',
    height: '1000px'
},
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    height: '1000px'
  },
  header: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    fontWeight: "bold",
    textAlign: "center"
  },
  viewer: {
    width: "800px",//window.innerWidth, //the pdf viewer will take up all of the width and height
    height: "750px"//window.innerHeight,
  }
});

// Create Document Component
const PreviewPdf = (props) => {
  return (
    <div>

      <PDFViewer style={styles.viewer}>
      <Document title="Formulario de Producto Terminado" pageLayout="useOC">
        <Page size="A4">
          <HeaderPDF form={props.form} />
        </Page>
      </Document>
    </PDFViewer>
		</div>
  );
};

export default PreviewPdf;
