/**
 * Component PdfGenerate
 * @author: Luis Avello
 * @version 1.0.0
 * @updated 2023-05-26
 */
import React, { useEffect, useState } from "react";
import { Box, Button, Paper, Tab, Tooltip, Typography } from "@mui/material";
import DataFormEmpty from "./data_form_empty";
import PdfProduct from "./pdf_product";
import PdfLine from "./pdf_line";
import PdfEmpty from "./pdf_empty";
import axios from "axios";
import jsPDF from "jspdf";

import { responseInfo, responseError } from "../utils/response_console";

const responsive = {
  xs: "repeat(1, 1fr)",
  sm: "repeat(2, 1fr)",
  md: "repeat(2, 1fr)",
  lg: "repeat(2, 1fr)",
  xl: "repeat(3, 1fr)",
};

const PdfGenerate = (props) => {
  const { order_id, code, formmain_id } = props;

  //let pdf_empty = PdfEmpty();

  const [data, setData] = useState(DataFormEmpty);
  const [datapdf, setDatapdf] = useState(PdfEmpty());
  const [show, setShow] = useState(true);
  //const urlForm = "/api/react/v1/quality/data_form";
  const urlDataPdf = "/api/react/v1/quality/data_pdf";

  useEffect(() => {
    console.log("AXIOS " + urlDataPdf + " with formmain_id: " + formmain_id);
    axios
      .get(urlDataPdf, {
        params: { formmain_id: formmain_id },
      })
      .then((response) => {
        console.log(
          "RESPONSE " + urlDataPdf + " with formmain_id: " + formmain_id
        );
        responseInfo(response, true);
        setData(response.data);
        setShow(true);
        if (response.data.code == "FINISHED_PRODUCT")
          setDatapdf(PdfProduct(response.data));
        else {
          //const pdf_line = PdfLine(response.data);
          //pdf_test = pdf_line;
          //setDatapdf(pdf_line);
          setDatapdf(PdfLine(response.data));
        }
      })
      .catch((error) => {
        responseError(error);
      });
  }, []);

  const handleBack = () => {
    window.history.back();
  };

  const handleSign = () => {
    console.log("Sign PDF");
    window.location = "/quality/dash";
  };

  return (
    <Box
      sx={{
        mt: [10, 12],
        mr: [2, 10],
        ml: [2, 10],
        mb: 5,
      }}
    >
      {/* HEADER */}
      <Paper elevation={1} sx={{ p: 1, mb: 3, mt: 3 }}>
        <Typography variant="h5">{"PDF " + data.header.name}</Typography>
      </Paper>
      <Paper elevation={2} sx={{ mb: 3, mt: 3 }}>
        {show ? (
          <object
            data={datapdf}
            width="95%"
            height="800"
            style={{ margin: "1%" }}
          ></object>
        ) : null}
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: responsive,
            gridTemplateAreas: {
              xs: `"buttons"`,
              sm: `" .  buttons"`,
              md: " .  buttons",
              lg: ". . buttons",
              xl: `". . buttons"`,
            },
            gap: 3,
          }}
        >
          <Box
            align="right"
            sx={{
              display: "grid",
              margin: "2%",
              gridTemplateColumns: "repeat(2, 1fr)",
              gridArea: "buttons",
            }}
          >
            <a href="#">
              <Button onClick={handleBack} sx={{ mt: 1 }}>
                Volver
              </Button>
            </a>
            <a href="#">
              <Button onClick={handleSign} variant="contained" sx={{ mt: 1 }}>
                Firmar
              </Button>
            </a>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default PdfGenerate;
