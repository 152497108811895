import React from "react";
import Chip from "@mui/material/Chip";
import SwipeableViews from "react-swipeable-views";
import Pagination from "./pagination";
import SectionForm from "./section-form";
import "../../styles/spinner.css";
import "../../styles/quality.css";

const styles = {
  root: {
    position: "relative"
  },
  slide: {
    height: 270,
    color: "#000"
  }
};

class SwipeableTabs extends React.Component {
  state = {
    index: 0,
    graphs: false
  };

  handleChangeIndex = index => {
    this.setState({
      index
    });
  };

  printIndex = () => {
    console.log(this.state.index);
  };

  formsIncompletedByOrder = order_id => {
    return this.props.forms(order_id).filter(form => {
      return !form.is_ready;
    });
  };

  formsCompletedByOrder = order_id => {
    return this.props.forms(order_id).filter(form => {
      return form.is_ready;
    });
  };

  /**
   * Retorna clase css segun estado
   * @param {String} state
   * @returns {String}
   */
  prettyClass = state => {
    return state == "running" ? "success" : "primary";
  };

  /**
   * Retorna estado legible para el usuario
   * @param {String} state
   * @returns {String}
   */
  prettyState = state => {
    return state == "running" ? "En Ejecución" : "Terminada";
  };

  setViewForms = () => {
    this.setState({ graphs: !this.state.graphs });
  };

  /**
   * Verifica si puede o no ser un formulario continuado o no
   * Se revisan dos condiciones:
   * 1) Si hay otra orden de la misma categoria
   * 2) Esta otra orden tiene formularios asociados
   * @param {Object} order 
   * @returns {Boolean}
   */
  canContinueForm = (order) => {
    let ordersByCategory = this.props.orders.filter(o => {
      return order.id != o.id && order.category.id == o.category.id
    });

    let orderWithForms = ordersByCategory.filter(o => {
      return this.props.forms(o.id).length > 0
    });

    return this.disponibleForms(orderWithForms);
  }

  /**
   * Retorna los formularios disponibles para continuar
   * @param {Array<Object>} orders 
   * @param {Array<Object>} 
   */

  disponibleForms = (orders) => {
    let ans = [];
    orders.forEach(o => {
      this.props.forms(o.id).forEach(form => {
        ans.push({...form, order: o });
      });
    })
    return ans;
  }

  render() {
    const { index } = this.state;
    const line = this.props.line;
    return (
      <div style={styles.root}>
        <SwipeableViews
          enableMouseEvents
          index={index}
          onChangeIndex={this.handleChangeIndex}
        >
          {this.props.orders.map((order, j) => {
            return (
              <div style={Object.assign({}, styles.slide)} key={j}>
                <div className="accordion activate">
                  <div>
                    <b className="order">N°{order.number}</b>{" "}
                    <Chip
                      className="chip-badge"
                      label={this.prettyState(order.state)}
                      color={this.prettyClass(order.state)}
                    />
                    {this.props.loadingForms ? (
                      <div className="spinner"></div>
                    ) : (
                      <>
                        <span className="badge badge-run">
                          <b>{this.formsCompletedByOrder(order.id).length}</b>
                        </span>
                        <span className="badge badge-danger">
                          <b>{this.formsIncompletedByOrder(order.id).length}</b>
                        </span>
                        <button onClick={() => this.setViewForms()}>
                          <i className="pms-i chart-bar black menu"></i>
                        </button>
                      </>
                    )}
                  </div>
                  <small>
                    {" "}
                    {order.product_name} - {order.product_code}
                  </small>
                </div>
                <SectionForm
                  line={line}
                  inspector={this.props.inspector}
                  order={order}
                  canContinueForm={this.canContinueForm(order)}
                  loadingForms={this.props.loadingForms}
                  forms={this.props.forms(order.id)}
                />
              </div>
            );
          })}
        </SwipeableViews>
        <Pagination
          dots={this.props.orders.length}
          index={index}
          onChangeIndex={this.handleChangeIndex}
        />
      </div>
    );
  }
}

export default SwipeableTabs;
