// Component MenuCreationForm
// @author: Vicente Illanes
// @version: 10.05.2022
// import React, { useState } from 'react';
import React from "react";
import Grid from "@mui/material/Grid";
import "../../styles/spinner.css";
import "../../styles/quality.css";
import PopperPopupState from "./popper.js";
import { WSRequestUpdateNumberOfInsepctions } from "../../utils/inspection_form_api";
class MenuCreationForm extends React.Component {
  state = {
    repeatedCategories: true,
    loading: false,
    error: false,
  };
  /* Redirecciona a vista de un nuevo formulario
   * @param {String} code
   */
  setCodeInspection = (code) => {
    if (code === "NEW_LINE") {
      window.location = "/quality/new_form_line";
    } else {
      if (code === "NEW_PRODUCT") {
        window.location = "/quality/new_form_product";
      } else {
        window.location =
          //"/quality/linef"
          "/inspection_form/new?code=" +
          code +
          "&id=" +
          this.props.production_order.id;
      }
    }
  };

  /**
   * Inicia la copia de un formulario para la orden de producción
   * @param {String} form_id
   */
  copyForm = async (form) => {
    const params = {
      form: form.code,
      order: this.props.production_order.id,
      inspector: this.props.inspector,
      type: "OLD",
      real_form_id: form.id,
    };
    this.setState({ loading: true });
    try {
      const url =
        URL_INIT + "/api/v4/inspection_form/update_number_of_inspections";
      const res = await WSRequestUpdateNumberOfInsepctions(url, params);
      const result = res.data;
      if (result.status)
        window.location = `/inspection_form/edit?id=${form.id}&order_id=${this.props.production_order.id}`;
    } catch (err) {
      this.setState({ error: true, loading: false });
    }
  };

  render() {
    const list_of_forms = [
      /*{ name: "Producto Terminado", code: "PRODUCT_FINISHED" },
      { name: "Línea", code: "LINE" },*/
      { name: "Producto Terminado", code: "NEW_PRODUCT" },
      { name: "Linea", code: "NEW_LINE" },
    ];
    return (
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <PopperPopupState
          title="Crear Formulario"
          content={
            <div>
              {list_of_forms.map((form, i) => {
                return (
                  <a
                    key={i}
                    onClick={() => this.setCodeInspection(form.code)}
                    className="dropdown-item-mui"
                  >
                    {form.name}
                  </a>
                );
              })}
            </div>
          }
        />
        {this.props.canContinueForm.length > 0 ? (
          <PopperPopupState
            title="Continuar Formulario"
            content={
              <div>
                {this.props.canContinueForm.map((form, i) => {
                  return (
                    <a
                      key={i}
                      onClick={() => this.copyForm(form)}
                      className="dropdown-item-mui"
                      style={{
                        fontSize: "12px",
                        padding: "2px",
                        margin: "2px",
                      }}
                    >
                      {form.name} - {form.category.label}
                    </a>
                  );
                })}
              </div>
            }
          />
        ) : null}
      </Grid>
    );
  }
}

export default MenuCreationForm;
