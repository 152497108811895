import * as React from "react";
import jsPDF, { AcroFormCheckBox } from "jspdf";
import logo from "../imgs/logo_varela.png";
import { autoTable } from "jspdf-autotable";
import dataTest from "./data_pdf_product.json";

function addCheckBox(field, pdf, option, fontSize) {
  field.readOnly = true;
  field.fontSize = fontSize;
  field.appearanceState = option;
  pdf.addField(field);
}

const getValueByCode = (list, code) => {
  let [first] = list.filter((item) => item.code == code);
  if (first === undefined) return "NOT FOUND CODE";
  else {
    let value = first.value;
    switch (first.type) {
      case "DATE":
        if (value != "") value = moment(first.value).format("DD/MM/YYYY");
        break;
      case "TIME":
        if (value != "") value = moment(first.value).format("HH:mm");
        break;
      case "SELECT":
        if (value != "") {
          let item = first.options.filter(
            (item) => item.value == first.value
          )[0];
          value = item.name;
        }
        break;
      default:
        value = first.value;
        break;
    }
    return value || "";
  }
};

const getSectionByCode = (list, code) => {
  let [first] = list.filter((item) => item.code == code);
  if (first === undefined) return {};
  else return first || {};
};

function PdfProduct(data) {
  let date = new Date().toLocaleDateString("es-PA");
  const pdf = new jsPDF("p", "mm", "letter");

  pdf.addImage(logo, "PNG", 15, 8, 14, 14);
  pdf.setFontSize(11);
  pdf.setFont("helvetica", "bold");

  pdf.text(
    ["INSPECCIÓN DE PRODUCTO TERMINADO", "Varela Hermanos S.A."],
    107.95,
    11,
    { align: "center" }
  );
  pdf.text(["Fecha:", "Producto:", "Tipo de Inspección:"], 15, 28, {
    align: "left",
  });
  pdf.text(["Presentación:", "Línea:"], 102, 28, { align: "right" });
  pdf.text(["Inspector:", "Material en SAP:"], 160, 28, { align: "right" });
  pdf.text(["INFORMACIÓN DEL PRODUCTO"], 107.95, 60, { align: "center" });

  pdf.setFontSize(7);
  pdf.text(
    [data.header.form_code, data.header.revision, data.header.updated],
    202,
    12,
    {
      align: "right",
    }
  );

  //const general = dataTest.sections[0].data;

  const section_2 = data.sections[1];
  const section_3 = data.sections[2];
  const section_4 = data.sections[3];
  const section_5 = data.sections[4];
  const section_6 = data.sections[5];
  const section_7 = data.sections[6];
  const section_8 = data.sections[7];
  const section_9 = data.sections[8];

  // Seccion 1
  const section_general = getSectionByCode(data.sections, "GENERAL");

  const section_4_s1 = section_4.sections[0];
  const section_8_s1 = data.sections[7].sections[0];
  let total_critic = section_8_s1.fields[2].value;

  /*const date_form = moment(
    getValueByCode(section_general.fields, "DATE_FORM")
  ).format("DD/MM/YYYY");*/
  const date_form = getValueByCode(section_general.fields, "DATE_FORM");
  const product_name = getValueByCode(section_general.fields, "PRODUCT_NAME");
  const presentation = getValueByCode(
    section_general.fields,
    "PRODUCT_PRESENTATION"
  );
  const inspector = getValueByCode(section_general.fields, "INSPECTOR");
  const material_sap = getValueByCode(section_general.fields, "MATERIAL_SAP");
  const tipo_inspeccion = getValueByCode(
    section_general.fields,
    "TYPE_INSPECTION"
  );

  pdf.setFont(undefined, "normal").setFontSize(11);
  pdf.text([date_form, product_name], 35, 28, {
    align: "left",
  });
  pdf.text([presentation, data.line.name], 104, 28, { align: "left" });
  pdf.text([inspector, material_sap], 162, 28, { align: "left" });
  //pdf.text(inspector + "\n" + material_sap, 162, 28, {
  //  align: "left",
  //});

  // Opciones de tipo de inspección
  pdf.setFontSize(9);
  pdf.text(["Producción"], 58, 37, { align: "left" });
  pdf.text(["Retrabajo (producto en proceso)"], 58, 42, { align: "left" });
  pdf.text(["Re-inspección por detención"], 58, 47, { align: "left" });
  pdf.text(["Transferencia de inventario"], 58, 52, { align: "left" });
  pdf.setFontSize(11);

  // Checkbox de tipo de inspección
  let produccion = new AcroFormCheckBox();
  produccion.Rect = [53, 34, 4, 4];
  let retrabajo = new AcroFormCheckBox();
  retrabajo.Rect = [53, 39, 4, 4];
  let reinspeccion = new AcroFormCheckBox();
  reinspeccion.Rect = [53, 44, 4, 4];
  let transferencia = new AcroFormCheckBox();
  transferencia.Rect = [53, 49, 4, 4];
  if (tipo_inspeccion === "PRODUCTION") {
    addCheckBox(produccion, pdf, "On", 11);
  } else {
    addCheckBox(produccion, pdf, "Off", 11);
  }
  if (tipo_inspeccion === "REWORK") {
    addCheckBox(retrabajo, pdf, "On", 11);
  } else {
    addCheckBox(retrabajo, pdf, "Off", 11);
  }
  if (tipo_inspeccion === "REINSPECT") {
    addCheckBox(reinspeccion, pdf, "On", 11);
  } else {
    addCheckBox(reinspeccion, pdf, "Off", 11);
  }
  if (tipo_inspeccion === "TRANSFER") {
    addCheckBox(transferencia, pdf, "On", 11);
  } else {
    addCheckBox(transferencia, pdf, "Off", 11);
  }

  //const producto = dataTest.sections[1].data;
  // Seccion 2
  const section_product_info = getSectionByCode(data.sections, "PRODUCT_INFO");

  const section_product_info_empty = getSectionByCode(
    section_product_info.sections,
    "PRODUCT_INFO_EMPTY"
  );
  const section_info_print_box = getSectionByCode(
    section_product_info.sections,
    "PRODUCT_INFO_PRINT_BOX"
  );
  const section_product_info_empty_2 = getSectionByCode(
    section_product_info.sections,
    "PRODUCT_INFO_EMPTY_2"
  );

  const hour_inspection = getValueByCode(
    section_product_info_empty.fields,
    "HOUR_INSPECTION"
  );

  const section_2_s1 = data.sections[1].sections[0];
  const section_2_s2 = data.sections[1].sections[1];
  const section_2_s3 = data.sections[1].sections[2];

  let fecha_hora = moment(section_2_s1.fields[0].value);
  let hora = fecha_hora.format("HH:mm");
  //if (fecha_hora !== "") {
  //  hora = fecha_hora.split("T")[1];
  //}
  const pallet = section_2_s1.fields[1].value;
  const trace_code = section_2_s1.fields[2].value;
  const barcode_product = section_2_s1.fields[3].value;
  const barcode_pack = section_2_s1.fields[4].value;
  const barcode_corrugated = section_2_s1.fields[5].value;
  const sanitary_registration = section_2_s1.fields[6].value;

  let print_box_date = "";
  const print_box_date_v = section_2_s2.fields[0].value;
  if (print_box_date_v !== "") {
    print_box_date = moment(print_box_date_v).format("DD/MM/YYYY");
  }
  let print_box_time = "";
  const print_box_time_v = section_2_s2.fields[1].value;
  if (print_box_time_v !== "") {
    print_box_time = moment(print_box_time_v).format("HH:mm");
  }

  //const print_box_time = moment(section_2_s2.fields[1].value).format("HH:mm");
  const print_box_import_by = section_2_s2.fields[2].value;
  const print_box_destiny = section_2_s2.fields[3].value;

  const destiny = section_2_s3.fields[0].value;
  const leyend_destiny = section_2_s3.fields[1].value;
  let options = section_2_s3.fields[1].options;

  const destination_legend = getValueByCode(
    section_product_info_empty_2.fields,
    "DESTINATION_LEGEND"
  );
  const product_appearance = getValueByCode(
    section_product_info_empty_2.fields,
    "PRODUCT_APPEARANCE"
  );
  const informed_person = getValueByCode(
    section_product_info_empty_2.fields,
    "INFORMED_PERSON"
  );
  const paper_tear = getValueByCode(
    section_product_info_empty_2.fields,
    "PAPER_TEAR"
  );
  const inspection_status = getValueByCode(
    section_product_info_empty_2.fields,
    "INSPECTION_STATUS"
  );

  const reason_inspection_status = getValueByCode(
    section_product_info_empty_2.fields,
    "REASON_INSPECTION_STATUS"
  );

  //const product_appearance = section_2_s3.fields[2].value;
  //const person_info = section_2_s3.fields[3].value;
  //const tear_paper = section_2_s3.fields[4].value;
  //const state_inspection = section_2_s3.fields[5].value;
  //const reason_state_inspection = section_2_s3.fields[6].value;

  let value = "L2602213RA0817\n000016";
  pdf.autoTable({
    head: [
      [
        "",
        "1era Inspección",
        "2da Inspección",
        "3era Inspección",
        "4ta Inspección",
        "5ta Inspección",
      ],
    ],
    body: [
      ["Hora-Pallet", hour_inspection + " " + pallet, "", "", "", ""],
      ["Código de Trazabilidad", trace_code, "", "", "", ""],
      ["C. de Barra del Producto", barcode_product, "", "", "", ""],
      ["C. de Barra (cajetilla)", barcode_pack, "", "", "", ""],
      ["C. de Barra (corrugado)", barcode_corrugated, "", "", "", ""],
      ["Registro Sanitario", sanitary_registration, "", "", "", ""],
      // {content: ['Impresión en la Caja', 'Imported by:\nBarco22525\nHAMBURG\n08:21', 'Varela Europa\n17/09/21\n09:22\n004-00552900', '', '', ''], rowSpan: 2},
      [
        {
          content: ["Impresión en la Caja"],
          rowSpan: 2,
          styles: { valign: "center" },
        },
        print_box_date + " " + print_box_time,
        "",
        "",
        "",
        "",
      ],
      [print_box_import_by + " " + print_box_destiny, "", "", "", ""],
      ["Destino", destiny, "", "", "", ""],
      ["Leyenda del destino", destination_legend, "", "", "", ""],
      ["Apariencia del producto", product_appearance, "", "", "", ""],
      ["Persona Informada", informed_person, "", "", "", ""],
      ["Desgarre de Papel", paper_tear, "", "", "", ""],
      ["Estado de Inspección", inspection_status, "", "", "", ""],
      [
        "Motivo del Estado de Inspección",
        reason_inspection_status,
        "",
        "",
        "",
        "",
      ],
    ],
    startY: 62,
    styles: { fontSize: 8, cellPadding: 1 },
    columnStyles: { 0: { fillColor: [221, 221, 221], textColor: [0, 0, 0] } },
    theme: "grid",
    headStyles: { halign: "center", fillColor: [41, 128, 186] },
  });

  const section_additional_supply_info = getSectionByCode(
    data.sections,
    "ADDITIONAL_SUPPLY_INFO"
  );

  const section_additional_supply_info_labels = getSectionByCode(
    section_additional_supply_info.sections,
    "ADDITIONAL_SUPPLY_INFO_LABELS"
  );

  const section_additional_supply_info_individual_pack = getSectionByCode(
    section_additional_supply_info.sections,
    "ADDITIONAL_SUPPLY_INFO_INDIVIDUAL_PACK"
  );

  const section_additional_supply_info_labels_front = getSectionByCode(
    section_additional_supply_info_labels.sections,
    "ADDITIONAL_SUPPLY_INFO_LABELS_FRONT"
  );
  const section_additional_supply_info_labels_recipebook = getSectionByCode(
    section_additional_supply_info_labels.sections,
    "ADDITIONAL_SUPPLY_INFO_LABELS_RECIPEBOOK"
  );
  const section_additional_supply_info_labels_neck = getSectionByCode(
    section_additional_supply_info_labels.sections,
    "ADDITIONAL_SUPPLY_INFO_LABELS_NECK"
  );
  const section_additional_supply_info_labels_shoulder = getSectionByCode(
    section_additional_supply_info_labels.sections,
    "ADDITIONAL_SUPPLY_INFO_LABELS_SHOULDER"
  );
  const section_additional_supply_info_labels_oval = getSectionByCode(
    section_additional_supply_info_labels.sections,
    "ADDITIONAL_SUPPLY_INFO_LABELS_OVAL"
  );
  const section_additional_supply_info_labels_strip = getSectionByCode(
    section_additional_supply_info_labels.sections,
    "ADDITIONAL_SUPPLY_INFO_LABELS_STRIP"
  );

  const hour_label = getValueByCode(
    section_additional_supply_info_labels.fields,
    "HOUR_LABEL"
  );

  const labels_front =
    getValueByCode(
      section_additional_supply_info_labels_front.fields,
      "SUPPLIER"
    ) +
    " " +
    getValueByCode(
      section_additional_supply_info_labels_front.fields,
      "DATE_PRODUCTION"
    );
  const labels_recipebook =
    getValueByCode(
      section_additional_supply_info_labels_recipebook.fields,
      "SUPPLIER"
    ) +
    " " +
    getValueByCode(
      section_additional_supply_info_labels_recipebook.fields,
      "DATE_PRODUCTION"
    );
  const labels_neck =
    getValueByCode(
      section_additional_supply_info_labels_neck.fields,
      "SUPPLIER"
    ) +
    " " +
    getValueByCode(
      section_additional_supply_info_labels_neck.fields,
      "DATE_PRODUCTION"
    );
  const labels_shoulder =
    getValueByCode(
      section_additional_supply_info_labels_shoulder.fields,
      "SUPPLIER"
    ) +
    " " +
    getValueByCode(
      section_additional_supply_info_labels_shoulder.fields,
      "DATE_PRODUCTION"
    );
  const labels_oval =
    getValueByCode(
      section_additional_supply_info_labels_oval.fields,
      "SUPPLIER"
    ) +
    " " +
    getValueByCode(
      section_additional_supply_info_labels_oval.fields,
      "DATE_PRODUCTION"
    );
  const labels_strip =
    getValueByCode(
      section_additional_supply_info_labels_strip.fields,
      "SUPPLIER"
    ) +
    " " +
    getValueByCode(
      section_additional_supply_info_labels_strip.fields,
      "DATE_PRODUCTION"
    );

  pdf.setFontSize(9).setFont(undefined, "bold");
  pdf.text(["Información Adicional de Suministros"], 15, 168, {
    align: "left",
  });
  pdf.setFontSize(7).setFont(undefined, "normal");
  pdf.text("Fecha de Producción:", 15, 173);
  pdf.text("Forma de Pegado", 178, 178);
  pdf.text(
    ["Autoad.", "Autoad.", "Autoad.", "Autoad.", "Autoad.", "Autoad."],
    178,
    187.5,
    { lineHeightFactor: 2 }
  );
  pdf.text(
    [
      "Cola Fría",
      "Cola Fría",
      "Cola Fría",
      "Cola Fría",
      "Cola Fría",
      "Cola Fría",
    ],
    192,
    187.5,
    { lineHeightFactor: 2 }
  );
  pdf.text("Etiquetas", 18, 200, { angle: 90 });
  pdf.text("Proveedor / Fecha de Prod.", 45, 210, { angle: 90 });
  pdf.text(
    [
      "HORA:",
      "Frente:",
      "Recetario:",
      "Cuello:",
      "Hombro:",
      "Ovalo:",
      "Strip:",
    ],
    23,
    182,
    { lineHeightFactor: 2 }
  );
  pdf.autoTable({
    head: [
      [
        "Inspección 1",
        "Inspección 2",
        "Inspección 3",
        "Inspección 4",
        "Inspección 5",
      ],
    ],
    body: [
      [hour_label, "", "", "", ""],
      [labels_front, "", "", "", ""],
      [labels_recipebook, "", "", "", ""],
      [labels_neck, "", "", "", ""],
      [labels_shoulder, "", "", "", ""],
      [labels_oval, "", "", "", ""],
      [labels_strip, "", "", "", ""],
    ],
    startY: 174,
    margin: { left: 50, right: 44 },
    styles: { fontSize: 7, cellPadding: 1 },
    theme: "grid",
    headStyles: { halign: "center", fillColor: [41, 128, 186] },
  });

  let colafria2 = new AcroFormCheckBox();
  colafria2.Rect = [188, 184.5, 3, 3];
  addCheckBox(colafria2, pdf, "Off", 8);

  let colafria3 = new AcroFormCheckBox();
  colafria3.Rect = [188, 189.5, 3, 3];
  addCheckBox(colafria3, pdf, "Off", 8);

  let colafria4 = new AcroFormCheckBox();
  colafria4.Rect = [188, 194.5, 3, 3];
  addCheckBox(colafria4, pdf, "Off", 8);

  let colafria5 = new AcroFormCheckBox();
  colafria5.Rect = [188, 199.5, 3, 3];
  addCheckBox(colafria5, pdf, "Off", 8);

  let colafria6 = new AcroFormCheckBox();
  colafria6.Rect = [188, 204.5, 3, 3];
  addCheckBox(colafria6, pdf, "On", 8);

  let colafria7 = new AcroFormCheckBox();
  colafria7.Rect = [188, 209.5, 3, 3];
  addCheckBox(colafria7, pdf, "Off", 8);

  let autoad2 = new AcroFormCheckBox();
  autoad2.Rect = [174, 184.5, 3, 3];
  addCheckBox(autoad2, pdf, "On", 8);

  let autoad3 = new AcroFormCheckBox();
  autoad3.Rect = [174, 189.5, 3, 3];
  addCheckBox(autoad3, pdf, "Off", 8);

  let autoad4 = new AcroFormCheckBox();
  autoad4.Rect = [174, 194.5, 3, 3];
  addCheckBox(autoad4, pdf, "Off", 8);

  let autoad5 = new AcroFormCheckBox();
  autoad5.Rect = [174, 199.5, 3, 3];
  addCheckBox(autoad5, pdf, "Off", 8);

  let autoad6 = new AcroFormCheckBox();
  autoad6.Rect = [174, 204.5, 3, 3];
  addCheckBox(autoad6, pdf, "Off", 8);

  let autoad7 = new AcroFormCheckBox();
  autoad7.Rect = [174, 209.5, 3, 3];
  addCheckBox(autoad7, pdf, "Off", 8);

  pdf.text("Fecha de Producción:", 15, 222);
  pdf.text("Cajetilla Individual", 18, 255, { angle: 90 });
  pdf.text(["HORA:"], 23, 232, { lineHeightFactor: 1.5 });
  pdf.text("Proveedor / Fecha de Prod.", 45, 260, { angle: 90 });
  pdf.autoTable({
    head: [
      [
        "Inspección 1",
        "Inspección 2",
        "Inspección 3",
        "Inspección 4",
        "Inspección 5",
      ],
    ],
    body: [
      ["", "", "", "", ""],
      ["", "", "", "", ""],
      ["", "", "", "", ""],
      ["", "", "", "", ""],
      ["", "", "", "", ""],
      ["", "", "", "", ""],
      ["", "", "", "", ""],
    ],
    startY: 224,
    margin: { left: 50 },
    styles: { fontSize: 7, cellPadding: 1 },
    theme: "grid",
    headStyles: { halign: "center", fillColor: [41, 128, 186] },
  });

  pdf.addPage();
  pdf.addImage(logo, "PNG", 15, 8, 14, 14);
  pdf.setFontSize(11).setFont("helvetica", "bold");
  pdf.text(
    ["INSPECCIÓN DE PRODUCTO TERMINADO", "Varela Hermanos S.A."],
    107.95,
    11,
    { align: "center" }
  );
  pdf.setFontSize(10);
  pdf.text(["DEFECTOS"], 107.95, 24, { align: "center" });
  pdf.text(["TIPOS DE DEFECTOS"], 107.95, 110, { align: "center" });

  pdf.autoTable({
    head: [
      [
        {
          content: "",
          styles: { halign: "center", fillColor: [41, 128, 186] },
        },
        {
          content: "Inspección 1",
          colSpan: 3,
          styles: { halign: "center", fillColor: [41, 128, 186] },
        },
        {
          content: "Inspección 2",
          colSpan: 3,
          styles: { halign: "center", fillColor: [41, 128, 186] },
        },
        {
          content: "Inspección 3",
          colSpan: 3,
          styles: { halign: "center", fillColor: [41, 128, 186] },
        },
        {
          content: "Inspección 4",
          colSpan: 3,
          styles: { halign: "center", fillColor: [41, 128, 186] },
        },
        {
          content: "Inspección 5",
          colSpan: 3,
          styles: { halign: "center", fillColor: [41, 128, 186] },
        },
      ],
      [
        {
          content: "",
          styles: { halign: "center", fillColor: [105, 166, 207] },
        },
        {
          content: "Menor",
          styles: { halign: "center", fillColor: [105, 166, 207] },
        },
        {
          content: "Mayor",
          styles: { halign: "center", fillColor: [105, 166, 207] },
        },
        {
          content: "Crítico",
          styles: { halign: "center", fillColor: [105, 166, 207] },
        },
        {
          content: "Menor",
          styles: { halign: "center", fillColor: [105, 166, 207] },
        },
        {
          content: "Mayor",
          styles: { halign: "center", fillColor: [105, 166, 207] },
        },
        {
          content: "Crítico",
          styles: { halign: "center", fillColor: [105, 166, 207] },
        },
        {
          content: "Menor",
          styles: { halign: "center", fillColor: [105, 166, 207] },
        },
        {
          content: "Mayor",
          styles: { halign: "center", fillColor: [105, 166, 207] },
        },
        {
          content: "Crítico",
          styles: { halign: "center", fillColor: [105, 166, 207] },
        },
        {
          content: "Menor",
          styles: { halign: "center", fillColor: [105, 166, 207] },
        },
        {
          content: "Mayor",
          styles: { halign: "center", fillColor: [105, 166, 207] },
        },
        {
          content: "Crítico",
          styles: { halign: "center", fillColor: [105, 166, 207] },
        },
        {
          content: "Menor",
          styles: { halign: "center", fillColor: [105, 166, 207] },
        },
        {
          content: "Mayor",
          styles: { halign: "center", fillColor: [105, 166, 207] },
        },
        {
          content: "Crítico",
          styles: { halign: "center", fillColor: [105, 166, 207] },
        },
        {
          content: "Menor",
          styles: { halign: "center", fillColor: [105, 166, 207] },
        },
        {
          content: "Mayor",
          styles: { halign: "center", fillColor: [105, 166, 207] },
        },
        {
          content: "Crítico",
          styles: { halign: "center", fillColor: [105, 166, 207] },
        },
      ],
    ],
    body: [
      [
        "Envases",
        "",
        "",
        total_critic,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ],
      ["Líquido", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
      ["Tapas", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
      ["Cápsula", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
      ["Corcho", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
      [
        "Etiqueta Frente",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ],
      [
        "Etiqueta Recetario",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ],
      [
        "Etiqueta Strip",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ],
      [
        "Etiqueta Hombro",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ],
      [
        "Etiqueta Cuello",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ],
      [
        "Etiqueta Óvalo",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ],
      [
        "Total de unidades\ndefectuosas por inspección",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ],
      [
        { content: "Cantidad Inspeccionada" },
        { content: "0 unidades", colSpan: 3 },
        { content: "0 unidades", colSpan: 3 },
        { content: "", colSpan: 3 },
        { content: "", colSpan: 3 },
        { content: "", colSpan: 3 },
      ],
    ],
    startY: 26,
    styles: { fontSize: 7, cellPadding: 1 },
    columnStyles: {
      0: { fillColor: [221, 221, 221], textColor: [0, 0, 0] },
      4: { fillColor: [245, 245, 245] },
      5: { fillColor: [245, 245, 245] },
      6: { fillColor: [245, 245, 245] },
      10: { fillColor: [245, 245, 245] },
      11: { fillColor: [245, 245, 245] },
      12: { fillColor: [245, 245, 245] },
    },
    theme: "grid",
  });

  pdf.autoTable({
    head: [
      [
        {
          content: "",
          styles: { halign: "center", fillColor: [41, 128, 186] },
        },
        {
          content: "Envases",
          colSpan: 2,
          styles: { halign: "center", fillColor: [41, 128, 186] },
        },
        {
          content: "Líquido",
          colSpan: 2,
          styles: { halign: "center", fillColor: [41, 128, 186] },
        },
        {
          content: "Tapas",
          colSpan: 2,
          styles: { halign: "center", fillColor: [41, 128, 186] },
        },
        {
          content: "Etiquetas",
          colSpan: 4,
          styles: { halign: "center", fillColor: [41, 128, 186] },
        },
      ],
    ],
    body: [
      [
        { content: "Defecto Crítico", rowSpan: 7 },
        { content: "Columpio (A)" },
        { content: "100" },
        {
          content: "Color no\nCaracterístico (M)",
          rowSpan: 4,
          styles: { valign: "center" },
        },
        { content: "", rowSpan: 4 },
        { content: "", rowSpan: 4 },
        { content: "", rowSpan: 4 },
        { content: "", rowSpan: 4 },
        { content: "", rowSpan: 4 },
        { content: "", rowSpan: 4 },
        { content: "", rowSpan: 4 },
      ],
      [{ content: "Rajaduras (B)" }],
      [{ content: "Aguja (C)" }],
      [{ content: "Aristas Cortante (D)" }],
      [
        { content: "Vidrio Suelto (E)" },
        { content: "" },
        {
          content: "Materia Extraña (N)",
          rowSpan: 3,
          styles: { valign: "center" },
        },
        { content: "", rowSpan: 3 },
        { content: "", rowSpan: 3 },
        { content: "", rowSpan: 3 },
        { content: "", rowSpan: 3 },
        { content: "", rowSpan: 3 },
        { content: "", rowSpan: 3 },
        { content: "", rowSpan: 3 },
      ],
      [{ content: "Vidrio Adherido (F)" }],
      [{ content: "Puente Roto (G)" }],

      [
        { content: "Defecto Mayor", rowSpan: 6 },
        { content: "Envase Rayado (H)" },
        { content: "" },
        { content: "Nivel Alto (O)", rowSpan: 2 },
        { content: "100", rowSpan: 2 },
        { content: "Liqueo en Tapas (Q)" },
        { content: "100" },
        { content: "Etiqueta Torcida (X)" },
        { content: "100" },
        { content: "Defecto de Impresión (AH)" },
        { content: "" },
      ],

      [
        { content: "Falta de Codificación (I)", rowSpan: 2 },
        { content: "", rowSpan: 2 },
        { content: "Fecha Incorrecta (R)" },
        { content: "" },
        { content: "Etiqueta Equivocada (Y)" },
        { content: "" },
        { content: "Color Incorrecto (AI)" },
        { content: "" },
      ],

      [
        { content: "Nivel Bajo (P)", rowSpan: 4 },
        { content: "", rowSpan: 4 },
        { content: "Tapa Rota (S)" },
        { content: "" },
        { content: "Falta de Etiqueta (AA)" },
        { content: "" },
        { content: "Invertidas (AJ)" },
        { content: "" },
      ],

      [
        { content: "Envase Manchado (J)", rowSpan: 3 },
        { content: "", rowSpan: 3 },
        { content: "Diferencia Excesiva\nen el Tono de Color (T)" },
        { content: "" },
        { content: "Leyenda Equivocada (AB)" },
        { content: "" },
        {
          content: "Diferencia Excesiva\nen el Tono de Color (AK)",
          rowSpan: 3,
        },
        { content: "", rowSpan: 3 },
      ],

      [
        { content: "Puente Roto (U)" },
        { content: "" },
        { content: "Etiqueta Arrugada (AC)", rowSpan: 2 },
        { content: "", rowSpan: 2 },
      ],

      [{ content: "Código Incorrecto (V)" }, { content: "" }],

      [
        { content: "Defecto Menor", rowSpan: 4 },
        { content: "Disparejo (K)" },
        { content: "5" },
        { content: "", rowSpan: 4 },
        { content: "", rowSpan: 4 },
        { content: "Tapa Floja (W)", rowSpan: 4 },
        { content: "", rowSpan: 4 },
        { content: "Etiqueta Rota (AD)" },
        { content: "" },
        { content: "Sucias (AL)" },
        { content: "" },
      ],
      [
        { content: "Fracturas que no Afectan la Resistencia (L)", rowSpan: 3 },
        { content: "8", rowSpan: 3 },
        { content: "Exceso de Pegamento (AE)" },
        { content: "" },
        { content: "Etiqueta Pelada (AM)" },
        { content: "" },
      ],
      [
        { content: "Falta de Pegamento (AF)" },
        { content: "" },
        { content: "Etiqueta Doblada (AN)" },
        { content: "100" },
      ],
      [
        { content: "Fuera de Centro (AG)" },
        { content: "100" },
        { content: "" },
        { content: "" },
      ],
      [
        { content: "" },
        { content: "Otros (Z)" },
        { content: "" },
        { content: "Otros (Z)" },
        { content: "" },
        { content: "Otros (Z)" },
        { content: "" },
        { content: "" },
        { content: "" },
        { content: "Otros (Z)" },
        { content: "" },
      ],
    ],
    startY: 112,
    styles: { fontSize: 7, cellPadding: 1, halign: "center", valign: "middle" },
    columnStyles: {
      0: { fillColor: [221, 221, 221], textColor: [0, 0, 0] },
      3: { fillColor: [245, 245, 245] },
      4: { fillColor: [245, 245, 245] },
      7: { fillColor: [245, 245, 245] },
      8: { fillColor: [245, 245, 245] },
    },
    theme: "grid",
  });

  let observacion = "";

  pdf.setFontSize(8);
  pdf.text(
    ["TOTAL DE DEFECTOS", "TOTAL DE UNIDADES DEFECTUOSAS CRÍTICO"],
    15,
    230,
    { lineHeightFactor: 2 }
  );
  pdf.text(
    [
      "TOTAL DE UNIDADES DEFECTUOSAS MAYOR",
      "TOTAL DE UNIDADES DEFECTUOSAS MENOR",
    ],
    120,
    230,
    { lineHeightFactor: 2 }
  );
  pdf.text("Observaciones", 15, 242, { align: "justify", maxWidth: 186 });
  pdf.setFont(undefined, "normal");
  pdf.text(observacion, 15, 247, { align: "justify", maxWidth: 186 });

  pdf.text(
    ["________________________________", "Aseguramiento de la Calidad"],
    59,
    265,
    { align: "center" }
  );
  pdf.text(["________________________________", "Operaciones"], 170, 265, {
    align: "center",
  });

  pdf.setFont(undefined, "bold");
  pdf.text("Informado:", 130, 265);
  pdf.text("Revisado por:", 15, 265);

  return pdf.output("bloburl");
}

export default PdfProduct;
