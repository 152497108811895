// Component BaseAwaitText
// @author: Vicente Illanes
// @version: 21.12.2022
// import React, { useState } from 'react';
import React from "react";
import Grid from '@mui/material/Grid';
import BaseAwaitText from "./base_await_text";

class InputQuerySelector extends React.Component {
  state = {
    domainQuery: "",
    domains: [],
    loading: false
  };

  queryWithoutOptions = () => {
    return (
      this.state.domainQuery &&
      this.state.domainQuery.length >= 3 &&
      !this.state.domains.length &&
      !this.state.loading
    );
  }

  render() {
    return (
        <Grid container columns={12}>
            <Grid item xs={12}>
                <BaseAwaitText
                    v-model="domainQuery"
                    placeholder="Ej: Zapatillas MiMarca de Mujer"
                ></BaseAwaitText>
            </Grid>
            <Grid item xs={12}>
                {/* {this.state.loading ?
                    <Skeleton /> : 
                    <>
                    {this.queryWithoutOptions() ?
                    <span>
                      -- La búsqueda no arrojó resultados --
                    </span> : null}
                    {this.domains && this.domains.length ?
                    }
                    <b-list-group v-else-if="domains && domains.length">
                        <b-list-group-item
                            v-for="d of domains"
                            :key="d.domain_id"
                            target="_blank"
                            href="#"
                            :active="activeDomain(d)"
                            @click="() => selectDomain(d)"
                        >
                            {{ d.domain_name }}
                        </b-list-group-item>
                        </b-list-group>

                    </>
                        
                    
                } */}
            </Grid>
      </Grid>
    );
  }
}

export default InputQuerySelector;
