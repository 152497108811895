import React from "react";
import Html from 'react-pdf-html';



const html = `<html>
<style>
.header { grid-area: main !important; }
.info-header { 
	grid-area: right !important; 
    text-align: right !important; 
}

.grid-container {
  display: grid !important;
  grid-template-areas:
    'main main main main right right' !important;
  padding: 10px !important;
  padding-bottom: 0 !important;
}

.grid-container > div {
  text-align: center !important;
  font-size: 10px !important;
}

ul {
  list-style-type: none;
  margin: 0 !important;
  padding: 0 !important;
}

p.company {
  font-style: oblique !important;
}
</style>
<div class="grid-container">
  <div class="header"><h1>FORMULARIO DE PRODUCTO TERMINADO</h1>
   <p class="company">Varela Hermanos, S.A</p>
  </div> 
  <div class="info_header"> 
 	 <ul> 
     	<li>ASC-F-005 </li>
        <li>REV: 7 </li>
        <li>27-abr-21 </li>
     </ul>
  </div>
</div>
<hr/>
</html>
`;


// Create Document Component
const HeaderPDF = () => {
  return (
    <Html resetStyles>{html}</Html>
  );
};

export default HeaderPDF;
