// Component MenuCreationForm
// @author: Vicente Illanes
// @version: 29.09.2022
import React from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardHeader from "@mui/material/CardHeader";
import { blue } from '@mui/material/colors';


import CardContent from "@mui/material/CardContent";
import SwipeableTabs from "./swipeable-tabs";
import "../../styles/spinner.css";
import "../../styles/quality.css";
import BaseAwaitText from "../base/base_await_text";
import BaseSelector from "../base/base_selector.js";
class DashboardQuality extends React.Component {

  state = {
    not_display_card: new Set(),
    value: "1"
  }
  /**
   *
   * @param {Object} line
   * @return {Boolean}
   */
  lineWithoutOrders = line => {
    const execute_orders = this.executeOrdersFilterByLine(line.id).length === 0;
    const done_orders = this.completedOrdersFilterByLine(line.id).length === 0;
    return execute_orders && done_orders;
  };
  /**
   * Filtra el conjunto de ordenes en produccion por linea
   * @param {Int} line_id
   * @returns {Array}
   */
  executeOrdersFilterByLine = line_id => {
    return this.props.executeOrders.filter(order => {
      return order.line_id === line_id;
    });
  };

  /**
   * Filtra el conjunto de ordenes completadas por linea
   * @param {Int} line_id
   * @returns {Array}
   */
  completedOrdersFilterByLine = line_id => {
    return this.props.completedOrders.filter(order => {
      return order.line_id === line_id;
    });
  };
  /**
   * Filtra el conjunto de ordenes completadas por linea
   * @param {Int} line_id
   * @returns {Array}
   */
  allOrdersFilterByLine = line_id => {
    return this.executeOrdersFilterByLine(line_id).concat(
      this.completedOrdersFilterByLine(line_id)
    );
  };

  /**
   * Da clase actual de tarjeta i-esima
   */
  getClassOfCard = (idx) => {
    if (!this.state.not_display_card.has(idx)){
      return {root: "body-card"};
    } else {
      return {root: "body-card not-display"};
    }
  }

  /**
   * Cambia estado de vista de la tarjeta i-esima
   */
  setDisplayCard = (idx) => {
    const current = this.state.not_display_card;
    if (!current.delete(idx)){
      current.add(idx)
    }
    this.setState({not_display_card: current});
  }

  onChangeValue = (newValue) => {
    this.setState({value: newValue});
  }

  render() {
    return (
      <>
        {/* <BaseAwaitText
            value={this.state.value}
            onChangeValue={this.onChangeValue}
        /> */}
        {/* <BaseSelector
          label="Producto"
          defaultValue={this.state.value}
          options={[{id: "1", label: "UNO"}, {id: "2", label: "DOS"}]}
          needNone={false}    
          onChangeValue={this.onChangeValue}             
        /> */}
        {console.log("props all lines: " + this.props.all_lines)}
        {this.props.all_lines.map((line, i) => {
          return (
            <Grid item xs={12} sm={12} md={6} lg={4} key={i} sx={{padding: "10px"}}>
              <Card sx={{ minWidth: 275 }}>
                <CardHeader
                  title={line.name}
                  classes={{root: "header header-dasboard "}}
                  action={
                    <a style={{ float: "right", cursor: "pointer"}} onClick={() => this.setDisplayCard(i)}>
                        <i className="pms-i compress white title_order"></i>
                    </a>
                  }
                />
                <CardContent classes={this.getClassOfCard(i)}>
                    {this.props.loadingOrders ? (
                      <div className="spinner"></div>
                    ) : (
                      <>
                        <SwipeableTabs
                          line={line}
                          inspector={this.props.inspector}
                          orders={this.allOrdersFilterByLine(line.id)}
                          forms={this.props.forms}
                          suffix={"En Ejecución"}
                          badge_class={"badge badge-run"}
                          loadingForms={this.props.loadingForms}
                          setOrderCurrent={this.props.setOrderCurrent}
                          isActivate={this.props.isActivate}
                        />
                        {this.lineWithoutOrders(line) ? (
                          <>
                            {"No hay ordenes en producción"}
                            <br />
                            {
                              "No hay ordenes terminadas en las ultimas 24 horas"
                            }
                          </>
                        ) : null}
                      </>
                    )}
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </>
    );
  }
}

export default DashboardQuality;
