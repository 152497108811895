// Component Header
// @author: Vicente Illanes
// @version: 10.05.2022
import React, { Component } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import TabsJS from "../layouts/tabs.js";
import "../../styles/menu.css";
import "../../styles/inspection_form/tab.css";
import { WSRequestSaveSection } from "../../utils/inspection_form_api";

class MenuButtonsSection extends Component {
  /**
   * Verifica si hay cambios o no en la seccion
   * @returns {Boolean}
   */
  notChanges = () => {
    return this.props.changes.size === 0;
  };

  /**
   * Prepara valores que se enviaran a peticion de guardado
   * @param {Array<Object} form_data
   * @returns {Object}
   */
  buildInputValues = (fields) => {
    let dict_values = {};
    fields.map((field) => {
      let key_field = this.props.buildKey(field);
      let value = this.props.answers[key_field];
      dict_values[key_field] = {
        id: field.id,
        val: `${value}`,
        cat: field.category_id,
      };
    });
    return dict_values;
  };

  /**
   * Obtiene todos los campos de una seccion
   * @returns {Array<Object>}
   */
  getAllFieldsSection() {
    let ans = [];
    let subSections = this.props.getSubSections(this.props.currentSection.id);
    subSections.forEach((sub_section) => {
      ans = ans.concat(
        this.props.getFields(this.props.currentSection.id, sub_section.id)
      );
    });

    return ans.filter(
      (field) => field.inspection === this.props.active_inspection
    );
  }
  /**
   * Prepara petición para guardar campos de una sección
   * @param {Object} e
   */
  saveDataSection = (e) => {
    e.preventDefault();
    const inputs = this.getAllFieldsSection();
    var dict_values = this.buildInputValues(inputs);
    const params = this.props.buildParams(dict_values);
    this.sendDataSection(params);
  };

  /**
   * Reemplaza valor de una llave especifica en una seccion
   * @param {Array<Object>} sections
   * @param {Object} section
   * @param {String} key
   * @param {String} val
   * @returns {Array<Object>}
   */
  replace_key_value_section = (sections, section, key, val) => {
    for (var i = 0; i < sections.length; i++) {
      if (sections[i].id === section.id) {
        sections[i][key] = val;
        return sections;
      }
    }
    return sections;
  };

  /**
   * Setea un estado en una seccion de una inspeccion especifica
   * @param {Object} section
   * @param {Boolean} status
   */
  statusSectionFields = (section, status) => {
    const current_inspection = this.props.active_inspection;
    return this.props.form.fields.map((field) => {
      const new_field = { ...field };
      if (
        field.section_id === section.id &&
        field.inspection === current_inspection
      ) {
        new_field.is_ready = status;
      }
      return new_field;
    });
  };

  /**
   * Envia a guardar valores de campos en una sección
   * @param {object} params
   */
  sendDataSection = async (params) => {
    this.props.setLoadingSave();
    try {
      const url = this.props.url_init + "/api/v4/inspection_form/save_section";
      const res = await WSRequestSaveSection(url, params);
      const result = res.data;
      this.updateDataSection(result.info);
    } catch (err) {
      this.props.setSaveWithErrors();
    }
  };

  /**
   * Actualiza información de la sección al actualizar campos
   * @returns
   */
  updateDataSection = (data) => {
    var new_form = this.props.form;
    new_form["fields"] = data.fields;
    new_form["inspection_form"] = data.inspection_form;
    this.props.updateNewData(data, new_form);
    this.props.openAlertSuccess();
  };

  /**
   * Captura cancelacion de una edicion
   * @param {Object} e
   */
  goToReadyStateSection = (e) => {
    e.preventDefault();
    this.cancelEditState();
  };

  /**
   * Reestablece Valores de las seccion de formulario
   */
  cancelEditState = () => {
    var new_form = this.props.form;
    new_form["sections"] = this.replace_ready_section(
      this.props.form.sections,
      this.props.currentSection
    );
    new_form["fields"] = this.AreReadyFieldsBySection(
      this.props.currentSection
    );
    const old_answers = this.props.revert_answers();
    const body = {
      edit_state: false,
      form: new_form,
      old_section: undefined,
      old_inputs: {},
      answers: old_answers,
      changes: new Set(),
    };
    this.props.setNewState(body);
  };

  /**
   *
   * @param {Object} section
   * @returns
   */
  AreReadyFieldsBySection = (section) => {
    return this.statusSectionFields(section, true);
  };

  /**
   * Setea una seccion como lista
   * @param {Array<Object>} sections
   * @param {Object} ready_section
   * @returns {Array<Object>}
   */
  replace_ready_section = (sections, ready_section) => {
    return this.replace_key_value_section(
      sections,
      ready_section,
      "is_ready",
      true
    );
  };

  /**
   * Controla la cantidad de cambios que se han realizado en la seccion de formulario
   * @param {String} oldValue
   * @param {String} value
   * @param {String} id
   */
  verificationChanges = (oldValue, value, id) => {
    if (oldValue !== value) {
      this.addChange(id);
    } else {
      this.removeChange(id);
    }
  };

  /**
   * Agrega un nuevo cambio al listado de cambios realizados
   * @param {String} id
   */
  addChange(id) {
    let changes = this.props.changes;
    changes.add(id);
    this.props.setNewState({ changes: changes });
  }

  /**
   * Quita un nuevo cambio al listado de cambios realizados
   * @param {String} id
   */
  removeChange(id) {
    let changes = this.props.changes;
    changes.delete(id);
    this.props.setNewState({ changes: changes });
  }

  /**
   * Filtra un conjunto de campos por la categoria indicada
   * @param {Array<Object>} fields
   * @param {<Object>} category
   * @returns
   */
  fieldsFilterBySpecificCategory = (fields, category) => {
    const category_id = category.id;
    const category_size = category.label.length;
    return fields.filter((f) => {
      return f.category_id == category_id || category_size == 0;
    });
  };

  /**
   * Autocompletar los campos de la categoria
   */
  autocompleteCurrentFields = () => {
    const category = this.props.categorySelected();
    const fields = this.props.getFieldsBySection(this.props.currentSection.id);
    const fieldsFiltered = this.fieldsFilterBySpecificCategory(
      fields,
      category
    );
    fieldsFiltered.forEach((field) => {
      const field_id = this.props.buildKey(field);
      if (this.props.answers[field_id] == "")
        this.props.setNewValue(field_id, 0);
    });
  };

  /**
   * Verifica si es una seccion de defectos la seccion actual
   * @returns {Boolean}
   */
  isSectionDefects = () => {
    return this.props.isSectionDefects(this.props.currentSection);
  };

  render() {
    return (
      <>
        <Button
          variant="contained"
          disabled={this.notChanges() || this.props.loading_section_save}
          onClick={(e) => this.saveDataSection(e)}
          sx={{ margin: "2px" }}
        >
          Siguiente
        </Button>
        <Button
          variant="outlined"
          disabled={this.notChanges() || this.props.loading_section_save}
          onClick={(e) => this.goToReadyStateSection(e)}
          sx={{ margin: "2px" }}
        >
          Restablecer
        </Button>
        {this.isSectionDefects() ? (
          <Button
            variant="outlined"
            disabled={this.props.loading_section_save}
            onClick={() => this.autocompleteCurrentFields()}
            sx={{ margin: "2px" }}
          >
            Autocompletar
          </Button>
        ) : null}
      </>
    );
  }
}

export default MenuButtonsSection;
