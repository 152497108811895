import Typography from '@mui/material/Typography'
import { TextField } from '@mui/material'
import Tab from '@mui/material/Tab'
import React, { useState } from 'react'
import {
    TabList,
    TabContext, TabPanel
} from '@mui/lab'
import { Box } from '@mui/system'
import info from './temp.json'
import Grid from '@mui/material/Grid'

export function Personal() {
    const [value, setValue] = useState('1')

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }
    const data = [{ field: info[62].field_name, val: info[62].value },
    { field: info[63].field_name, val: info[63].value },
    { field: info[64].field_name, val: info[64].value },
    { field: info[65].field_name, val: info[65].value },
    { field: info[66].field_name, val: info[66].value },
    { field: info[67].field_name, val: info[67].value },
    { field: info[68].field_name, val: info[68].value },
    { field: info[69].field_name, val: info[69].value }]

    const [open] = useState(true)
    return (
        <div>
            <Grid container spacing={4}>
                {open &&
                    data.map((item) => (
                        <Grid item xs={12} sm={6} xl={4}>
                            <Typography>{item.field}</Typography>
                            <TextField fullWidth InputProps={{ readOnly: true }} value={item.val} />
                        </Grid>
                    ))}
            </Grid>

        </div>
    )
}