// Component SectionForm
// @author: Vicente Illanes 
// @version: 10.05.2022
// import React, { useState } from 'react';
import React  from 'react';
import Grid from '@mui/material/Grid';
import "../../styles/spinner.css";
import "../../styles/quality.css";
import MenuCreationForm from './menu-creation-form';
import CardForm from "./card-form";


class SectionForm extends React.Component {  
    state = {
        offset: 0
    }
    formsByOrder = () =>{
        return this.props.forms.slice(this.state.offset, this.state.offset + 3);
    }

    hasRightForm = () => {
        return this.state.offset + 3 < this.props.forms.length;
    }
    subOneOffset = () => {
        this.setState({offset: this.state.offset - 1});
    }
    addOneOffset = () => {
        this.setState({offset: this.state.offset + 1});
    }
    hasLeftForm = () => {
        return this.state.offset != 0;
    }
    render () {
        return(         
            <div style={{margin: "5px"}}>
                <MenuCreationForm
                  production_order={this.props.order}
                  canContinueForm={this.props.canContinueForm}
                  inspector={this.props.inspector}
                />
                {this.props.loadingForms ? 
                <div className="spinner"></div> :
                <Grid 
                  container 
                  spacing={{ xs: 2, md: 3, md: 3, lg:3}} 
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }} 
                  columns={{ xs: 12, sm: 12, md: 12, lg:12 }}
                  sx={{margin: "5px"}}
                >
                    {this.props.forms.length === 0 ? <p style={{margin: "30px"}}>{"No hay formularios creados"}<br/></p> : null}
                    <Grid item xs={1} sm={1} md={1} lg={1}>
                    {this.hasLeftForm() ?   
                      <button className="button-form button-arrow button-arrow-left" onClick={() => {this.subOneOffset()}}>
                        <i className="pms-i arrow-left white menu"></i>
                      </button> : null}
                    </Grid>
                    {this.formsByOrder().map((form, k) => {
                        return ( 
                            <CardForm form={form} i={k+1} key={k} /> )})
                    }
                    {this.hasRightForm() ?
                    <Grid item xs={1} sm={1} md={1} lg={1}>
                      <button 
                        className="button-form button-arrow button-arrow-right" 
                        onClick={() => {this.addOneOffset()}}
                      >
                        <i className="pms-i arrow-right white menu"></i>
                      </button>
                    </Grid> : null}
                </Grid>
            }
            </div> 
        );
    }            
}


export default SectionForm;


