import React, { useState } from 'react'
import Typography from '@mui/material/Typography'
import { FormControlLabel, TextField } from '@mui/material'
import { Box } from '@mui/system'
import Grid from '@mui/material/Grid'
import Switch from '@mui/material/Switch'
import EtiquetasComp from './EtiquetasComp'
import info from './temp.json'

export function Etiquetas() {
    const [value, setValue] = React.useState('1')

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

/* 
    const data = [
        { field: info[19].field_name, proveedor: info[19].prov, pref: info[19].extra, no: info[19].value, rad: info[19].check },
        { field: info[20].field_name, proveedor: info[20].prov, pref: info[20].extra, no: info[20].value, rad: info[20].check },
        { field: info[21].field_name, proveedor: info[21].prov, pref: info[21].extra, no: info[21].value, rad: info[21].check },
        { field: info[22].field_name, proveedor: info[22].prov, pref: info[22].extra, no: info[22].value, rad: info[22].check },
        { field: info[23].field_name, proveedor: info[23].prov, pref: info[23].extra, no: info[23].value, rad: info[23].check },
        { field: info[24].field_name, proveedor: info[24].prov, pref: info[24].extra, no: info[24].value, rad: info[24].check }

    ] */

    function isCompleted(sel, num, peg) {
        if (sel !== "" && num !== "" && peg !== "") {
            return true
        }
    }
    let hora_arranque = new Date().toLocaleTimeString('en-GB')
    let fecha_prod = new Date().toLocaleDateString('en-GB')
    const [open] = useState(true)
    const [isChecked1, setIsChecked1] = useState(true);
    const [isChecked2, setIsChecked2] = useState(true);
    const [isChecked3, setIsChecked3] = useState(true);
    const [isChecked4, setIsChecked4] = useState(true);
    const [isChecked5, setIsChecked5] = useState(true);
    const [isChecked6, setIsChecked6] = useState(true);
    return (
            <div>

                <Typography variant='h6' sx={{ m: 1 }} align='center'>Etiquetas</Typography>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={12} md={12} xl={12}>
                        <Typography align='center' sx={{ m: 1 }}>HORA</Typography>
                        <TextField fullWidth value={hora_arranque}/>
                    </Grid>
                </Grid>
                <Grid container spacing={4} variant='outlined' sx={{ mt: 1 }}>
                    <Grid item xl={6} xs={12}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                            <FormControlLabel
                                control={<Switch defaultChecked checked={isChecked1} onChange={() => setIsChecked1(!isChecked1)} />}
                                label='Frente'
                            />
                            {isChecked1 && <EtiquetasComp pre={info[19].pre} prov={info[19].prov} num={info[19].value} check={info[19].check} />}
                        </Box>
                    </Grid>
                    <Grid item xl={6} xs={12}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                            <FormControlLabel
                                control={<Switch defaultChecked checked={isChecked2} onChange={() => setIsChecked2(!isChecked2)} />}
                                label='Recetario'
                            />
                            {isChecked2 && <EtiquetasComp pre={info[20].pre} prov={info[20].prov} num={info[20].value} check={info[20].check}/>}
                        </Box>
                    </Grid>
                    <Grid item xl={6} xs={12}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                            <FormControlLabel
                                control={<Switch defaultChecked checked={isChecked3} onChange={() => setIsChecked3(!isChecked3)} />}
                                label='Cuello'
                            />
                            {isChecked3 && <EtiquetasComp pre={info[21].pre} prov={info[21].prov} num={info[21].value} check={info[21].check}/>}
                        </Box>
                    </Grid>
                    <Grid item xl={6} xs={12}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                            <FormControlLabel
                                control={<Switch defaultChecked checked={isChecked4} onChange={() => setIsChecked4(!isChecked4)} />}
                                label='Hombro'
                            />
                            {isChecked4 && <EtiquetasComp pre={info[22].pre} prov={info[22].prov} num={info[22].value} check={info[22].check}/>}
                        </Box>
                    </Grid>
                    <Grid item xl={6} xs={12}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                            <FormControlLabel
                                control={<Switch defaultChecked checked={isChecked5} onChange={() => setIsChecked5(!isChecked5)} />}
                                label='Ovalo'
                            />
                            {isChecked5 && <EtiquetasComp pre={info[23].pre} prov={info[23].prov} num={info[23].value} check={info[23].check}/>}
                        </Box>
                    </Grid>
                    <Grid item xl={6} xs={12}>
                        <Box sx={{ display: 'inline-flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                            <FormControlLabel
                                control={<Switch inline defaultChecked checked={isChecked6} onChange={() => setIsChecked6(!isChecked6)} />}
                                label='Strip'
                            />
                            {isChecked6 && <EtiquetasComp pre={info[24].pre} prov={info[24].prov} num={info[24].value} check={info[24].check} />}
                        </Box>
                    </Grid>
                </Grid>

                <Typography variant='h6' sx={{ mt: 3, mb: 1 }} align='center'>Pegamento</Typography>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={6} md={4} xl={4}>
                        <Typography>Tipo de Goma</Typography>
                        <TextField fullWidth id="outlined-basic" variant="outlined" value={info[25].value} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} xl={4}>
                        <Typography>Fecha de Producción</Typography>
                        <TextField fullWidth value={fecha_prod}/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} xl={4}>
                        <Typography>Fecha de Expiración</Typography>
                        <TextField fullWidth id="outlined-basic" variant="outlined" />
                    </Grid>
                </Grid>
                <Typography variant='h6' sx={{ mt: 3, mb: 1 }} align='center'>Caja de Cartón Corrugado</Typography>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={6} md={4} xl={4}>
                        <Typography>Proveedor</Typography>
                        <TextField fullWidth id="outlined-basic" variant="outlined" value={info[28].value}/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} xl={4}>
                        <Typography>Fecha de producción</Typography>
                        <TextField fullWidth value={fecha_prod}/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} xl={4}>
                        <Typography>Sellado</Typography>
                        <TextField fullWidth id="outlined-basic" variant="outlined" value={info[30].value} />
                    </Grid>
                </Grid>
                <Typography variant='h6' sx={{ mt: 3, mb: 1 }} align='center'>Tapas</Typography>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={6} md={4} xl={4}>
                        <Typography>Proveedor</Typography>
                        <TextField fullWidth id="outlined-basic" variant="outlined" value={info[31].value}/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} xl={4}>
                        <Typography>Modelo</Typography>
                        <TextField fullWidth id="outlined-basic" variant="outlined" value={info[32].value}/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} xl={4}>
                        <Typography>NR/OP</Typography>
                        <TextField fullWidth id="outlined-basic" variant="outlined" value={info[33].value}/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} xl={4}>
                        <Typography>Fecha de producción</Typography>
                        <TextField fullWidth value={fecha_prod}/>
                    </Grid>
                </Grid>
                <Typography variant='h6' sx={{ mt: 3, mb: 1 }} align='center'>Tipo de Envase</Typography>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={6} md={4} xl={4}>
                        <Typography>Proveedor</Typography>
                        <TextField fullWidth id="outlined-basic" variant="outlined" value={info[35].value}/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} xl={4}>
                        <Typography>Fecha de producción</Typography>
                        <TextField fullWidth value={fecha_prod}/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} xl={4}>
                        <Typography>VR/PET/Otros</Typography>
                        <TextField fullWidth id="outlined-basic" variant="outlined" value={info[37].value}/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} xl={4}>
                        <Typography>Tipo de Lavado</Typography>
                        <TextField fullWidth id="outlined-basic" variant="outlined" value={info[38].value}/>
                    </Grid>
                </Grid>

            </div>
    )
}