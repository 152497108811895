// Component Header
// @author: Vicente Illanes
// @version: 10.05.2022
import React, { Component } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {es} from 'date-fns/locale'
import TabsJS from "../layouts/tabs.js";
import "../../styles/menu.css";
import "../../styles/inspection_form/tab.css";
import MenuButtonsSection from "./menu_buttons_section.js";
import BaseSelector from "../base/base_selector.js";
class FormSection extends Component {
  /**
   * Entrega los campos filtrados por seccion, subseccion e inspeccion activa
   * @param {String} section_id
   * @param {String} sub_section_id
   * @returns {Array<Object>}
   */
  getFieldsByInspection = (section_id, sub_section_id) => {
    var active_inspection = this.props.active_inspection;
    const sub_section = this.getSubSection(sub_section_id);
    if (!sub_section.is_repeatable) {
      active_inspection = 1;
    }
    return this.props.form.fields.filter(
      field =>
        field.sub_section_id === sub_section_id &&
        field.section_id === section_id &&
        field.inspection === active_inspection
    );
  };

  /**
   * Entrega subseccion asociada por id
   * @param {String} sub_section_id
   * @returns {Object}
   */
  getSubSection = sub_section_id => {
    return this.props.form.sub_sections.filter(
      sub_section => sub_section.id === sub_section_id
    )[0];
  };

  /**
   * Verifica si una seccion esta lista o no
   * Esto corresponde a cuando todos los campos de
   * la seccion estan rellenados
   * @param {Object} section
   * @returns {Boolean}
   */
  sectionIsReadyByInspection(section) {
    const current_inspection = this.props.active_inspection;
    const fields_by_section = this.props.form.fields.filter(
      field => field.section_id === section.id
    );
    const fields_by_inspection = fields_by_section.filter(
      field => field.inspection === current_inspection
    );
    const fields_are_ready = fields_by_inspection.filter(
      filter => filter.is_ready
    );
    return fields_by_inspection.length === fields_are_ready.length;
  }

  /**
   * Prepara petición para guardar campos de una sección
   * @param {Object} e
   */
  saveDataSection = e => {
    this.sendDataSection(params);
  };

  /**
   * Indica que la seccion no esta todavia lista
   * @param {Array<Object>} sections
   * @param {Object} section
   * @returns
   */
  notReadySection = (sections, section) => {
    return this.replace_key_value_section(sections, section, "is_ready", false);
  };

  /**
   * Reemplaza valor de una llave especifica en una seccion
   * @param {Array<Object>} sections
   * @param {Object} section
   * @param {String} key
   * @param {String} val
   * @returns {Array<Object>}
   */
  replace_key_value_section = (sections, section, key, val) => {
    for (var i = 0; i < sections.length; i++) {
      if (sections[i].id === section.id) {
        sections[i][key] = val;
        return sections;
      }
    }
    return sections;
  };

  /**
   * Setea una seccion como lista
   * @param {Array<Object>} sections
   * @param {Object} ready_section
   * @returns {Array<Object>}
   */
  replace_ready_section = (sections, ready_section) => {
    return this.replace_key_value_section(
      sections,
      ready_section,
      "is_ready",
      true
    );
  };

  /**
   * Retorna el tipo de input que se necesita
   * @param {Object} type
   * @returns {String}
   */
  getTypeField = type => {
    const code = type.code;
    switch (code) {
      case "2_OPTIONS_TAGS":
        return "selector_options";
      case "SELECTOR_MODEL":
        return "selector";
      case "TIME":
        return "time";
      case "DATE":
        return "date";
      case "LARGE TEXT":
        return "textarea";
      case "INTEGER":
      case "DEFECT_CRITICAL":
      case "DEFECT_MAYOR":
      case "DEFECT_MINOR":
        return "number";
      case "TEXT":
      default:
        return "text";
    }
  };

  /**
   * Añade informacion extra a campos dependiendo del tipo
   * @param {String} type
   * @returns {Object}
   */
  addExtraInfo = type => {
    return type == "number" ? { min: 0 } : {};
  };

  buildSelectorInput = field => {
    const field_id = this.props.buildKey(field);
    return (
      <BaseSelector
        label={field.name}
        defaultValue={this.props.answers[field_id]}
        options={field.type.options}
        needNone={false}
        onChangeValue={e => this.set_field_value(field_id, e)}
      />
    );
  };

  buildSelectorOptionsInput = field => {
    const field_id = this.props.buildKey(field);
    return (
      <BaseSelector
        label={field.name}
        defaultValue={this.props.answers[field_id]}
        options={field.type.options}
        needNone={false}
        onChangeValue={value => this.setNewValue(field_id, value)}
      />
    );
  };

  buildNumericInput = field => {
    const field_id = this.props.buildKey(field);
    return (
      <TextField
        id={`${field.id}`}
        name={"field_" + field.id}
        label={field.name}
        type="number"
        InputLabelProps={{
          shrink: true
        }}
        size="small"
        inputProps={{ min: "0" }}
        value={this.props.answers[field_id]}
        onChange={e => this.set_field_value(field_id, e)}
      />
    );
  };

  buildTimeInput = field => {
    const field_id = this.props.buildKey(field);
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TimePicker
          label={field.name}
          value={this.props.answers[field_id]}
          onChange={e => this.set_field_value(field_id, e)}
          renderInput={params => <TextField size="small" {...params} />}
        />
      </LocalizationProvider>
    );
  };

  buildDateField = field => {
    const field_id = this.props.buildKey(field);
    return (
      <LocalizationProvider adapterLocale={es}  dateAdapter={AdapterDateFns}>
        <DatePicker
          label={field.name}
          disableMaskedInput
          value={new Date(this.props.answers[field_id])}
          onChange={e => this.setNewValue(field_id, e)}
          openTo="day"
          inputFormat="dd-MMMM-yyyy"
          views={["day", "month", "year"]}
          renderInput={params => (
            <TextField
              size="small"
              InputLabelProps={{
                shrink: true
              }}
              {...params}
            />
          )}
        />
      </LocalizationProvider>
    );
  };

  /**
   * Entrega elemento html input para un campo especifico
   * @param {Object} field
   * @returns {HTMLInputElement}
   */
  getFieldInput(field) {
    const type = this.getTypeField(field.type);
    if (type === "selector_options")
      return this.buildSelectorOptionsInput(field);
    if (type === "selector") return this.buildSelectorInput(field);
    if (type === "number") return this.buildNumericInput(field);
    // if (type === "time") return this.buildTimeInput(field);
    if (type === "date") return this.buildDateField(field);
    const field_id = this.props.buildKey(field);
    if (type !== "textarea") {
      return (
        <TextField
          id={`${field.id}`}
          label={field.name}
          name={"field_" + field.id}
          size="small"
          InputLabelProps={{
            shrink: true
          }}
          value={this.props.answers[field_id]}
          onChange={e => this.set_field_value(field_id, e)}
        />
      );
    } else {
      return (
        <textarea
          id={field.id}
          name={"field_" + field.id}
          cols="80"
          rows="20"
          value={this.props.answers[field_id]}
          onChange={e => this.set_field_value(field_id, e)}
        />
      );
    }
  }

  /**
   * Envia evento de cambio de valor de un campo en particular
   * @param {String} id
   * @param {Object} event
   */
  set_field_value = (id, event) => {
    console.log(id);
    console.log(event);
    const value = event.target.value;
    this.setNewValue(id, value);
  };

  /**
   * Envia seteo de nuevo valor de un campo
   * @param {String} id
   * @param {String} value
   */
  setNewValue = (id, value) => {
    const oldValue = this.props.oldValues[id];
    this.verificationChanges(oldValue, value, id);
    this.props.setNewValueToField(id, value);
  };

  /**
   * Controla la cantidad de cambios que se han realizado en la seccion de formulario
   * @param {String} oldValue
   * @param {String} value
   * @param {String} id
   */
  verificationChanges = (oldValue, value, id) => {
    if (oldValue !== value) {
      this.addChange(id);
    } else {
      this.removeChange(id);
    }
  };

  /**
   * Agrega un nuevo cambio al listado de cambios realizados
   * @param {String} id
   */
  addChange(id) {
    let changes = this.props.changes;
    changes.add(id);
    this.props.setNewState({ changes: changes });
  }

  /**
   * Quita un nuevo cambio al listado de cambios realizados
   * @param {String} id
   */
  removeChange(id) {
    let changes = this.props.changes;
    changes.delete(id);
    this.props.setNewState({ changes: changes });
  }

  /**
   * Construe elemento HTML input de campos que estan activos según categoría seleccionada
   * Si la categoria tiene label = '' pasa el campo
   * @param {Object} subSection
   * @returns
   */
  getFieldsByCategory = subSection => {
    const category = this.props.categorySelected(subSection.id);

    const fields = this.props.getFields(
      this.props.currentSection.id,
      subSection.id
    );
    const fieldsFiltered = fields.filter(f => {
      return f.category_id == category.id || category.label.length == 0;
    });
    return this.buildFieldsInputElements(fieldsFiltered, subSection);
  };

  /**
   * Retorna un arreglo con labels y ids de las categorias de la subseccion actual
   * @param {Object} sub_section
   * @returns {Array<Object>}
   */
  getCurrentCategoriesLabelsAndValues = sub_section => {
    let ans = [];
    this.props
      .getCategories(this.props.currentSection.id, sub_section.id)
      .forEach(cat => {
        let dict = { label: cat.label, value: cat.id };
        ans.push(dict);
      });
    return ans;
  };

  /**
   * Entega campos en elementos por categoria
   * @param {Object} subSection
   * @returns {Array<Array<Object>>}
   */
  getFieldsByAllCategories = subSection => {
    let categories = this.props.getCategories(
      this.props.currentSection.id,
      subSection.id
    );
    const fields = this.props.getFields(
      this.props.currentSection.id,
      subSection.id
    );
    let ans = [];
    categories.forEach(category => {
      const fieldsFiltered = this.fieldsFilterBySpecificCategory(
        fields,
        category
      );
      ans.push(
        <Grid
          container
          spacing={{ xs: 2, sm: 3, md: 3, lg: 3 }}
          columnSpacing={{ xs: 0.5, sm: 0.5, md: 0.5, lg: 0.5 }}
          columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
          sx={{ padding: "10px" }}
        >
          {this.buildFieldsInputElements(fieldsFiltered, subSection)}
        </Grid>
      );
    });
    return ans;
  };

  /**
   * Filtra un conjunto de campos por la categoria indicada
   * @param {Array<Object>} fields
   * @param {<Object>} category
   * @returns
   */
  fieldsFilterBySpecificCategory = (fields, category) => {
    const category_id = category.id;
    const category_size = category.label.length;
    return fields.filter(f => {
      return f.category_id == category_id || category_size == 0;
    });
  };

  /**
   * Construye arreglo con elementos HTML de un conjunto de campos
   * @param {Array<Object>} fields
   * @param {<Object>} subSection
   */
  buildFieldsInputElements = (fields, subSection) => {
    const fieldInputs = fields.map((field, j) => {
      return field.inspection === this.props.active_inspection ||
        !subSection.is_repeatable ? (
        <Grid item xs={4} sm={4} md={4} xl={4} key={j}>
          {this.getFieldInput(field)}
        </Grid>
      ) : null;
    });
    return fieldInputs;
  };

  AllSectionsDefectsNotReady = () => {
    let sections = this.props.form.sections;
    let ans = false;
    sections.forEach(section => {
      if (this.isSectionDefects(section) && !section.is_ready) {
        ans = true;
      }
    });
    return ans;
  };

  /**
   * Determina si una corresponde a una seccion con defectos
   * @param {Object} section
   * @returns {Boolean}
   */
  isSectionDefects = section => {
    return section.name.toLowerCase().includes("defectos");
  };

  /**
   * Retorna el numero total de defectos en la inspeccion
   * @returns {Number}
   */
  totalDefects = () => {
    let critical = this.totalDefectsCritical();
    let mayor = this.totalDefectsHigh();
    let minor = this.totalDefectsLow();
    return critical + mayor + minor;
  };

  /**
   * Retorna el numero total de defectos criticos en la inspeccion
   * @returns {Number}
   */
  totalDefectsCritical = () => {
    return this.totalDefectsByType("CRITICAL");
  };

  /**
   * Retorna el numero total de defectos mayores en la inspeccion
   * @returns {Number}
   */
  totalDefectsHigh = () => {
    return this.totalDefectsByType("MAYOR");
  };
  /**
   * Retorna el numero total de defectos menores en la inspeccion
   * @returns {Number}
   */
  totalDefectsLow = () => {
    return this.totalDefectsByType("MINOR");
  };

  /**
   * Dado un tipo de defecto,
   * retorna el numero total de defectos de ese tipo en la inspeccion
   * @returns {Number}
   */
  totalDefectsByType = code => {
    let fields = this.filterFieldsByDefectType(code);
    let active_inspection = this.props.active_inspection;
    let filteredFields = fields.filter(
      field => field.inspection === active_inspection
    );
    return this.sumFieldsValue(filteredFields);
  };

  /**
   * Filtra filtros por tipo de defecto
   * @param {String} category
   * @returns  {Array<Object>}
   */
  filterFieldsByDefectType = category => {
    return this.props.allDefectsFields().filter(field => {
      let field_type = field.type.code;
      return field_type.includes(category);
    });
  };
  /**
   * Verifica si el formulario actual cuenta con sumarizacion de defectos
   * @returns {Boolean}
   */
  defectsPresent() {
    return (
      this.filterFieldsByDefectType("CRITICAL").length > 0 ||
      this.filterFieldsByDefectType("MAYOR").length > 0 ||
      this.filterFieldsByDefectType("MINOR").length > 0
    );
  }

  /**
   * Calcula la cantidad de defectos
   * @param {Array<Object>} filteredFields
   * @returns {Integer}
   */
  sumFieldsValue = filteredFields => {
    let ans = 0;
    filteredFields.forEach(field => {
      const field_id = this.props.buildKey(field);
      let answer = Number(this.props.answers[field_id]);
      if (answer > 0) ans += answer;
    });
    return ans;
  };

  render() {
    const fieldWithValues = this.props.getNumOfResponseBySection(
      this.props.currentSection,
      this.getFieldsByInspection
    );
    return (
      <>
        <div className="page-header" style={{ textAlign: "center" }}>
          <h5 style={{ textAlign: "center" }}>
            Sección: {this.props.currentSection.name}
          </h5>
          {fieldWithValues}%
          <progress id="file" value={fieldWithValues} max="100"></progress>
        </div>
        <div>
          <form>
            {this.props
              .getSubSections(this.props.currentSection.id)
              .map((sub_section, i) => {
                return (
                  <div className="subsection" key={i}>
                    {sub_section.code.split("_")[0] !== "EMPTY" ? (
                      <h6>{sub_section.name}</h6>
                    ) : (
                      <hr />
                    )}
                    <Grid
                      container
                      spacing={{ xs: 2, sm: 3, md: 3, lg: 3 }}
                      columnSpacing={{ xs: 0.5, sm: 0.5, md: 0.5, lg: 0.5 }}
                      columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                      sx={{ padding: "10px" }}
                    >
                      {this.props.categorySelected(sub_section.id).id ? (
                        <TabsJS
                          currentValue={
                            this.props.categorySelected(sub_section.id).id
                          }
                          headerTabs={this.getCurrentCategoriesLabelsAndValues(
                            sub_section
                          )}
                          handleChange={val =>
                            this.props.setCategorySelected(val, sub_section.id)
                          }
                          tabPanels={this.getFieldsByAllCategories(sub_section)}
                        />
                      ) : (
                        this.getFieldsByCategory(sub_section)
                      )}
                    </Grid>
                  </div>
                );
              })}
            <hr />
            {this.props.currentSection.name == "Resumen" &&
            this.defectsPresent() ? (
              <>
                <h6> Resumen de Defectos Inspección </h6>
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <div>
                      <b>Total de Defectos: </b> {this.totalDefects()} <br />
                      <b>Total de Defectos Críticos: </b>{" "}
                      {this.totalDefectsCritical()} <br />
                      <b>Total de Defectos Mayores: </b>{" "}
                      {this.totalDefectsHigh()} <br />
                      <b>Total de Defectos Menores: </b>{" "}
                      {this.totalDefectsLow()}
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      disabled={
                        this.totalDefects() === 0 ||
                        this.AllSectionsDefectsNotReady()
                      }
                      variant="contained"
                    >
                      {" "}
                      Crear Ticket
                    </Button>
                  </Grid>
                </Grid>
                <hr />
              </>
            ) : null}
            <MenuButtonsSection
              form={this.props.form}
              currentSection={this.props.currentSection}
              changes={this.props.changes}
              answers={this.props.answers}
              loading_section_save={this.props.loading_section_save}
              active_inspection={this.props.active_inspection}
              url_init={this.props.url_init}
              getSubSections={this.props.getSubSections}
              getFields={this.props.getFields}
              buildKey={this.props.buildKey}
              buildParams={this.props.buildParams}
              setSaveWithErrors={this.props.setSaveWithErrors}
              updateNewData={this.props.updateNewData}
              openAlertSuccess={this.props.openAlertSuccess}
              setLoadingSave={this.props.setLoadingSave}
              revert_answers={this.props.revert_answers}
              setNewState={this.props.setNewState}
              categorySelected={this.props.categorySelected}
              getFieldsBySection={this.props.getFieldsBySection}
              fieldsFilterBySpecificCategory={
                this.fieldsFilterBySpecificCategory
              }
              setNewValue={this.setNewValue}
              isSectionDefects={this.isSectionDefects}
            />
          </form>
        </div>
      </>
    );
  }
}

export default FormSection;
